import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddOns from "./AddOns";
import AstroPujaList from "./AstroPujaList";
import Category from "./Category";
import MandirPujaList from "./MandirPujaList";
import Mandir from "./Mandir";

const Puja = () => {
  const { route } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState(route || "astro-puja");

  useEffect(() => {
    if (route !== value) {
      setValue(route);
    }
  }, [route, value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/${newValue}`);
  };

  return (
    <div>
      <h3>Puja List</h3>
      <Box sx={{ width: "100%", marginBlock: "1rem" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="wrapped label tabs example"
        >
          <Tab value="puja-category" label="Category" wrapped></Tab>
          <Tab value="puja-addons" label="Add Ons" wrapped></Tab>
          <Tab value="astro-puja" label="Astro Puja" wrapped></Tab>
          <Tab value="mandir" label="Mandir" wrapped></Tab>
          <Tab value="mandir-puja" label="Mandir Puja" wrapped></Tab>
        </Tabs>
      </Box>
      {(() => {
        switch (value) {
          case "puja-category":
            return <Category />;
          case "puja-addons":
            return <AddOns />;
          case "astro-puja":
            return <AstroPujaList />;
            case "mandir":
              return <Mandir />;
          case "mandir-puja":
            return <MandirPujaList />;
            
          default:
            return <div>Oops, Something went wrong!</div>;
        }
      })()}
    </div>
  );
};

export default Puja;

