import React from "react";
import Puja from "../components/Puja/Puja";

const PujaPage = () => {
  return (
      <Puja />
  );
};

export default PujaPage;
