import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./astrologer.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import { BiFilter, BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import { IoMdAdd } from "react-icons/io";
import { deleteBanner_api, getBanners_api, statusUpdate_banner_api } from "../api/banner";
import { invoiceListing } from "../api/astrologer";
import { DialogBox } from "./DialogBox";


const InvoiceListing = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [isLoading, setisLoading] = useState(false);
    const [allInvoices, setallInvoices] = useState([]);
    const [pageCount, setpageCount] = useState(parseInt(localStorage?.getItem("listingIndex")) || 1);
    const [deleteId, setdeleteId] = useState("");
    const [deletePopup, setdeletePopup] = useState(false);
    const [currentGroup, setcurrentGroup] = useState({});
    const [pageLength, setpageLength] = useState();
    const [search, setsearch] = useState("");
    const [updateSettlement, setUpdateSettlement] = useState({});
    const [isSettledValue, setIsSettledValue] = useState("");
    const [open, setOpen] = useState(false);
    const [invoiceData, setInvoiceData] = useState({});
    useEffect(() => {
        fetchAstroAllInvoiceFunc();
    }, [pageCount, location]);

    async function fetchAstroAllInvoiceFunc(data) {
        setisLoading(true);
        try {
            const temp = {
                id: location.state?.data?.id,
                page: pageCount,
                limit: 10,
                search: search.trim(),
            };
            let res = await invoiceListing(temp);
            if (res.data.status) {
                setallInvoices(res?.data?.data?.invoices);
                setpageLength(res?.data?.data?.totalPages);
                setisLoading(false);
            } else {
                setisLoading(false);
                console.log("status false!");
            }
        } catch (error) {
            console.log(error);
        }
    }

    function openModel(data) {
        console.log(data);
        setOpen(true);
        setInvoiceData(data);
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "var(--clr-theme)",
            color: theme.palette.common.white,
            fontWeight: "bold",
            borderRight: "1px solid #fff",
            overflow: "hidden",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: "none",
            borderLeft: "2px solid #00000011",
            "&:last-child": {
                borderRight: "2px solid #00000011",
            },
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        borderBottom: "2px solid #00000011",
    }));

    return (
        <div className="">
            <div className="beat_heading">
                <div className={s["user-list-heading"]}>
                    <div className="user-list-title">
                        <h3>Invoices{location.state?.data ? ' - ' : ''} {location.state?.data?.id}</h3>
                    </div>
                    <div className={s["user-list-search"]}>
                    </div>
                </div>
                <div className="beat_left">
                </div>
            </div>
            <div className="beat_table">
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                        <StyledTableCell align="center">Invoice No.</StyledTableCell>
                        <StyledTableCell align="center">Astro Name</StyledTableCell>
                        <StyledTableCell align="center">Invoice Date</StyledTableCell>
                        <StyledTableCell align="center">
                            Number of Transactions
                        </StyledTableCell>
                        <StyledTableCell align="center">Amount to Credit</StyledTableCell>
                        <StyledTableCell align="center">Settlement Status</StyledTableCell>
                        <StyledTableCell align="center">Download Invoice</StyledTableCell>
                        <StyledTableCell align="center">
                            Settlement Details
                        </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allInvoices?.map((row, index) => (
                            <StyledTableRow key={'innv-'+ row.id}>
                                <StyledTableCell align="center">
                                {row.invoice_no}
                                </StyledTableCell>
                                <StyledTableCell>{row?.recipient_name}</StyledTableCell>
                                <StyledTableCell align="center">
                                {row.createdAt.slice(0, 10)}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                {row?.txns_count}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                {row?.invoice_amount}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                
                                <div className={row?.settlement_status == 'Settled' ? 'astrologer_active_admin__UiG0i' : 'astrologer_inactive_admin__arGrO'}>{row?.settlement_status || 'Not Settled'}</div>
                                
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <div className={s["astrologer-action-btn"]} style={{ flexWrap: "wrap" }}  >
                                        <span
                                            onClick={() =>
                                                navigate("/invoice", {
                                                    state: {
                                                        data: row,
                                                    },
                                                })
                                            }
                                        >
                                            View Invoice
                                        </span>
                                    </div>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                <div onClick={() => openModel(row)} className="astrologer-action-btn">
                                    <span>Settlement</span>
                                </div>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                {allInvoices?.length <= 0 && <DataNotFound />}
                {allInvoices?.length > 0 && (
                    <div className={s["pagination"]}>
                        <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => {setpageCount(value); localStorage.setItem("listingIndex",value)}} page={pageCount} />
                    </div>
                )}
                
                <DialogBox
                    open={open}
                    setIsSettledValue={setIsSettledValue}
                    handleClose={() => setOpen(false)}
                    data={invoiceData}
                    setUpdateSettlement={setUpdateSettlement}
                    setisLoading={setisLoading}
                />
            </div>
            <Loder loading={isLoading} />
        </div>
    );
};

export default InvoiceListing;
