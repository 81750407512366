import axios from "axios";
import Cookies from "js-cookie";
import { getBaseUrl } from "../../utils";

const pujaAddons = getBaseUrl() + "admin/addons";
const deleteAddons = getBaseUrl() + "admin/addons";
const addAddons = getBaseUrl() + "admin/addons";
const updateAddons = getBaseUrl() + "admin/addons";

export const getPujaAddons = async (data) => {
    let config = {
      method: "get",
      url: pujaAddons + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };

  export const updatePujaAddons = async (data, id) => {
    let config = {
      method: "patch",
      url: updateAddons + `/${id}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };

  export const deletePujaAddons = async (data) => {
    let config = {
      method: "delete",
      url: deleteAddons + `/${data.id}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };

  export const addPujaAddons = async (data) => {
    let config = {
      method: "post",
      url: addAddons,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };