import React, { useState } from "react";
import { Card, CircularProgress, Grid, Box, Tabs, Tab } from "@mui/material";
import AstrologerAdd from "../components/Astrologer/AstrologerAdd";
import { AddAstrologerSlabPricing } from "../components/Astrologer/AddAstrologerSlabPricing";

const AddAstrologer = () => {
  const [value, setValue] = useState("one");
  const [astrologerId, setAstrologerId] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      {/* <AstrologerAdd /> */}
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="wrapped label tabs example"
        >
          <Tab value="one" label="Basic Details" wrapped></Tab>
          <Tab value="two" label="Slab Pricing"></Tab>
        </Tabs>
      </Box>
      {value === "one" ? (
        <AstrologerAdd setAstrologerId={setAstrologerId} />
      ) : (
        <AddAstrologerSlabPricing astrologerId={astrologerId} />
      )}
    </div>
  );
};

export default AddAstrologer;
