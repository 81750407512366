import React from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

const addSlab = getBaseUrl() + "admin/slabs"
const postPrice = getBaseUrl() + "admin/priceSlabs"

export const addAstrologerSlab_api = async (data) => {
    let config = {
      method: "post",
      url: addSlab,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };

  export const getAstrologerSlab_api = async () => {
    let config = {
      method: "get",
      url: addSlab,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };
  export const deleteAstrologerSlab_api = async (id) => {
    let config = {
      method: "delete",
      url: addSlab+"/"+id,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };

  export const postPriceAstrologerSlab_api = async (data) => {
    let config = {
      method: "post",
      url: postPrice,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };

  export const patchPriceAstrologerSlab_api = async (data,id) => {
    let config = {
      method: "patch",
      url: postPrice+"/"+id,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };

  export const getPriceAstrologerSlab_api = async (data) => {
    let config = {
      method: "get",
      url: postPrice+"?astroId="+data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };
