import React from "react";
import AstrologerSlabAdd from "../components/Astrologer/AstrologerSlabAdd";

const AstrologerSlab = () => {
  return (
    <div>
      <AstrologerSlabAdd />
    </div>
  );
};

export default AstrologerSlab;
