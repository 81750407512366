import { Autocomplete, Card, Chip, Grid, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { addMandirPuja, updateMandirPuja } from "../api/mandirPuja";
import s from "./Puja.module.css";
import { getMandirList } from "../api/mandir";
import { getPujaAddons } from "../api/pujaAddons";

const AddMandirPuja = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [ mandirList, setMandirList ] = useState([])
  const [ addonsList, setAddonsList ] = useState([]);
  const [ name, setName ] = useState("");
  const [ description, setDescription ] = useState("");
  const [ slug, setSlug ] = useState("");
  const [ mrp, setMrp ] = useState("")
  const [ price, setPrice ] = useState("")
  const [ pujaDate, setPujaDate ] = useState("");
  const [ endDate, setEndDate ] = useState("");
  const [ participantCount, setParticipantCount ] = useState("");
  const [ aboutPuja, setAboutPuja ] = useState("");
  const [ file, setFile ] = useState(null);
  const [ templeId, setTempleId ] = useState("")
  const [ addonsId, setAddonsId ] = useState([])
  const [ benefits, setBenefits ] = useState([])
  const [ selectedBenefits, setSelectedBenefits ] = useState([]);
  const [ process, setProcess ] = useState([])
  const [ selectedProcess, setSelectedProcess ] = useState([])
  const [ status, setStatus ] = useState("");
  const [ id, setId ] = useState("")
  const [pageCount, setpageCount] = useState(parseInt(localStorage?.getItem("catgIndex")) || 1);
  const [search, setsearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchMandirList();
    fetchAddons()
  }, [pageCount, search]);

  async function fetchMandirList() {
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
      };
      let { data } = await getMandirList(temp);
      if (data.status) {
        setMandirList(data?.data?.temples);
      } else {
        setIsLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsLoading(false);
    }
  }

  async function fetchAddons() {
    setIsLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
      };
      let res = await getPujaAddons(temp);
      if (res.data.status) {
        setAddonsList(res?.data?.data?.addons);
      } else {
        setIsLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }


  const pagetype = location.state.pagetype;

  useEffect(() => {
    setId(location?.state?.data?.id)
    setName(location?.state?.data?.name);
    setDescription(location?.state?.data?.description);
    setStatus(location?.state?.data?.status)
    setSlug(location?.state?.data?.slug)
    setMrp(location?.state?.data?.mrp)
    setPrice(location?.state?.data?.price)
    setPujaDate(location?.state?.data?.actual_pooja_time?.slice(0, -2))
    setEndDate(location?.state?.data?.pooja_booking_end_time?.slice(0, -2))
    setParticipantCount(location?.state?.data?.participation_count)
    setAboutPuja(location?.state?.data?.additional_details?.aboutPuja)
    setTempleId(location?.state?.data?.temple_id)
    setAddonsId(location?.state?.data?.addons || []);
    setBenefits(location?.state?.data?.additional_details?.benefits || []);
    setSelectedBenefits(location?.state?.data?.additional_details?.benefits || []);
    setProcess(location?.state?.data?.additional_details?.process || []);
    setSelectedProcess(location?.state?.data?.additional_details?.process || []);
  }, [location]);



  const pujaFunc = async () => {
    if (pagetype == "Add") {
      const fd = new FormData();
      fd.append("productImage", file);
      fd.append("name", name);
      fd.append("description", description);
      fd.append("status", status);
      fd.append("slug", slug);
      fd.append("mrp", mrp);
      fd.append("price", price);
      fd.append("actualPoojaTime", new Date(pujaDate).toISOString());
      fd.append("scheduledTime", new Date(endDate).toISOString());
      fd.append("participationCount", participantCount);
      fd.append("aboutPooja", aboutPuja);
      fd.append("templeId", templeId);
      fd.append("addons", addonsId);
      fd.append("benefits", JSON.stringify(selectedBenefits));
      fd.append("process", JSON.stringify(selectedProcess));
      try {
        let res = await addMandirPuja(fd);
        if (res.data.status) {
          navigate("/mandir-puja");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      const fd = new FormData();
     {file && fd.append("productImage", file)}
      fd.append("name", name);
      fd.append("description", description);
      fd.append("status", status);
      fd.append("slug", slug);
      fd.append("mrp", mrp);
      fd.append("price", price);
      fd.append("actualPoojaTime", new Date(pujaDate).toISOString());
      fd.append("scheduledTime", new Date(endDate).toISOString());
      fd.append("participationCount", participantCount);
      fd.append("aboutPooja", aboutPuja);
      fd.append("templeId", templeId);
      fd.append("addons", addonsId);
      fd.append("benefits", JSON.stringify(selectedBenefits));
      fd.append("process", JSON.stringify(selectedProcess));
      try {
        let res = await updateMandirPuja(fd, id);
        if (res.data.status) {
          navigate("/mandir-puja");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (mandirList?.length > 0 && !templeId) {
      setTempleId(mandirList[0]?.id); 
    }
  }, [mandirList, templeId]);

  const memoizedMandirList = useMemo(() => mandirList, [mandirList]);

  console.log("status", location?.state?.data?.temple_id?.toString())

  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["back-button"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

             {/* Name */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Title</label>
                <textarea type="text" className="form-control" name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
              </div>
            </Grid>

             {/* Description */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Description</label>
                <textarea type="text" className="form-control" name="description" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description" />
              </div>
            </Grid>

              {/* Slug */}
               <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Slug</label>
                <input type="text" className="form-control" name="slug" value={slug} onChange={(e) => setSlug(e.target.value)} placeholder="Slug" />
              </div>
            </Grid>

             {/* MRP */}
             <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">MRP</label>
                <input type="text" className="form-control" name="mrp" value={mrp} onChange={(e) => setMrp(e.target.value)} placeholder="MRP" />
              </div>
            </Grid>


             {/* Price */}
             <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Price</label>
                <input type="text" className="form-control" name="price" value={price} onChange={(e) => setPrice(e.target.value)} placeholder="Price" />
              </div>
            </Grid>

            {/* Image */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Select Image</label>
                <div className="mr-2">
                  <input type="file" className="form-control" name="img" placeholder="" accept="image/*" onChange={(e) => setFile(e.target.files[0])} />
                </div>
              </div>
            </Grid>

             {/* Puja Date */}
             <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Scheduled Date</label>
                <div className="mr-2">
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={pujaDate}
                    onChange={(e) => setPujaDate(e.target.value)}
                  />
                </div>
              </div>
            </Grid>


            {/* End Date */}
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Booking End Date</label>
                <div className="mr-2">
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
              </div>
            </Grid>

             {/* Participant Count */}
             <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Participant Count</label>
                <input type="text" className="form-control" name="participantCount" value={participantCount} onChange={(e) => setParticipantCount(e.target.value)} placeholder="Count" />
              </div>
            </Grid>

               {/* About Puja */}
               <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">About Puja</label>
                <textarea type="text" className="form-control" name="aboutPuja" value={aboutPuja} onChange={(e) => setAboutPuja(e.target.value)} placeholder="About Puja" />
              </div>
            </Grid>

            {/* Mandir List */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Select Mandir</label>
                <div className="mr-2">
                  <div className="form-group">
                    <select className="form-control" name="templeId" value={templeId || ""} onChange={(e) =>{setTempleId(e.target.value)}}>
                      {
                        memoizedMandirList?.map((item)=>(
                          <option key={item?.id} value={item?.id}>{item?.name}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
              </div>
            </Grid>
            
             {/* Addons List */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Addons</label>
                <div className="mr-2">
                  <div className="form-group">
                  <Autocomplete
                   multiple
                   id="addons-autocomplete"
                   options={addonsList}
                   getOptionLabel={(option) => {
                     return typeof option === 'string' ? option : option?.name;
                   }}
                   value={addonsId?.map(id => addonsList.find(addon => addon.id === id) || id)}
                   onChange={(event, newValue) => {
                     const updatedAddons = newValue.map((item) => {
                       if (typeof item === 'string') {
                         return { id: item, name: item };
                       }
                       return item; 
                     });
                     setAddonsId(updatedAddons?.map((item) => item.id));
                       }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={typeof option === 'string' ? option : option?.id}
                            variant="outlined"
                            label={typeof option === 'string' ? option : option?.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined"  />
                      )}
                    />             
                  </div>
                </div>
              </div>
            </Grid>

             {/* Benefits */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Benefits</label>
                <div className="mr-2">
                  <div className="form-group">
                  <Autocomplete
                   multiple
                   className={s["chips"]}
                   id="benefits-autocomplete"
                   options={benefits?.filter((item) => !selectedBenefits.includes(item))}
                   freeSolo
                   value={selectedBenefits}
                   getOptionLabel={(option) => option}  
                   onChange={(event, newValue) => {
                    setSelectedBenefits(newValue.map((item) => (typeof item === 'string' ? item : item)));
                  }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={option}
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined"  />
                      )}
                    />
                  </div>
                </div>
              </div>
            </Grid>

             {/* Process */}
             <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Process</label>
                <div className="mr-2">
                  <div className="form-group">
                  <Autocomplete
                   multiple
                   className={s["chips"]}
                   id="process-autocomplete"
                   options={process?.filter((item) => !selectedProcess.includes(item))}
                   freeSolo
                   value={selectedProcess}
                   getOptionLabel={(option) => option}  
                   onChange={(event, newValue) => {
                    setSelectedProcess(newValue.map((item) => (typeof item === 'string' ? item : item)));
                  }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={option}
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined"  />
                      )}
                    />
                  </div>
                </div>
              </div>
            </Grid>

            {/* Status */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Status</label>
                <div className="mr-2">
                  <div className="form-group">
                  <Select
        labelId="status-label"
        id="status"
        value={status || ""}
        onChange={(e) => setStatus(e.target.value)} 
        fullWidth
      >
        <MenuItem value="draft">Draft</MenuItem>
        <MenuItem value="published">Published</MenuItem>
        {/* <MenuItem value="deleted">Deleted</MenuItem> */}
      </Select>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => pujaFunc()}>
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddMandirPuja;
