import React, { useEffect, useState } from "react";
import s from "./astrologer.module.css";
import { Grid } from "@mui/material";
import { Dialog, DialogActions, DialogTitle, DialogContent } from "@mui/material";
import { updateSettlement } from "../api/astrologer";

export const DialogBox = ({
    open, handleClose, setIsSettledValue, data, setisLoading, setUpdateSettlement,
}) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [setlVal, setsetlVal] = useState(true);
    const [utrNum, setUtrNum] = useState("");

    useEffect(() => {
        if(data.settlement_status == 'Settled'){
            setIsDisabled(true);
        }else{
            setIsDisabled(false);
        }
        if(data.utr_number !== ''){
            setUtrNum(data.utr_number);
        }
    }, [data]);

    async function updateSettlementData(inv_data) {
        console.log("SATTLE_DATA>>>", inv_data)
        setisLoading(true);
        try {
            let res = await updateSettlement(inv_data);
            if (res.data.status) {
                setUpdateSettlement(res.data.data);
                setisLoading(false);
            } else {
                setisLoading(false);
            }
            setisLoading(false);
        } catch (error) { }
    }

    const [dateval, setDateval] = useState(
        new Date()
            .toLocaleDateString("en-IN", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            })
            .split("/")
            .map((x) => x.padStart(2, 0))
            .reverse()
            .join("-")
    );
    return (
        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            maxWidth="xs"
            onClose={handleClose}
        >
            <DialogTitle className={s.dialog_title}>
                <div>Settlement Details</div>
            </DialogTitle>
            <DialogContent className={s.cardpopup_content}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Invoice Paid</label>
                            <div className="mr-2">
                                <div className="form-group">
                                    <select
                                        className="form-control"
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => {
                                            setIsSettledValue(e.target.value);
                                            setsetlVal(e.target.value);
                                        }}
                                        disabled={isDisabled}
                                    >
                                        <option value="true">Paid</option>
                                        <option value="false">Not Paid</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="form-group">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Settlement Date</label>
                                <input
                                    className="form-control"
                                    type="date"
                                    disabled={isDisabled}
                                    value={dateval}
                                    onChange={(e) => setDateval()} />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Pay Ref No.</label>
                            <input
                                type="text"
                                className="form-control"
                                name="utrNum"
                                disabled={isDisabled}
                                value={data.utr_number}
                                onChange={(e) => setUtrNum(e.target.value)}
                                placeholder="Ref No." />
                        </div>
                    </Grid>
                </Grid>
                <div style={{ display: "flex", gap: "1rem" }}>
                    <div
                        className={s.employee_gl_popup}
                        onClick={() => {
                            handleClose(false);
                            setTimeout(() => {
                                setIsDisabled(true);
                            }, 100);
                            const DataSet = {
                                invoice_no: data.invoice_no,
                                settlement_date: dateval,
                                is_settled: setlVal,
                                utr_number: utrNum,
                            };
                            console.log("SATTLE_DATA>>>", data)
                            updateSettlementData(DataSet);
                        }}
                    >
                        Save
                    </div>
                    {isDisabled && (
                        <div
                            className={s.employee_gl_popup_del}
                            onClick={() => {
                                setIsDisabled(false);
                            }}
                        >
                            Edit
                        </div>
                    )}
                </div>
            </DialogContent>
            <DialogActions></DialogActions>
        </Dialog>
    );
};
