import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "../Users/user.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Pagination } from "@mui/material";
import { fetchAllUsers, user_delete_api, user_status_api } from "../api/user";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import { BiFilter, BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import DeletePopup from "../Dialogbox/DeletePopup";
import FilterPopup from "../Dialogbox/FilterPopup";
import { IoMdAdd } from "react-icons/io";
import { deleteAstrologerGallery_api, getAstrologerGallery_api } from "../api/astrologer";
import { Dialog, DialogActions, DialogTitle, DialogContent } from "@mui/material";


const AstrologerGallery = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const [isLoading, setisLoading] = useState(false);
  const [allUsers, setallUsers] = useState([]);
  const [pageCount, setpageCount] = useState(parseInt(localStorage?.getItem("astrogalleryIndex")) || 1);
  const [deleteId, setdeleteId] = useState("");
  const [deletePopup, setdeletePopup] = useState(false);
  const [currentGroup, setcurrentGroup] = useState({});
  const [pageLength, setpageLength] = useState();
  const [search, setsearch] = useState("");
  console.log("location", location.state)
  useEffect(() => {
    fetchAllUsersFunc();
  }, [pageCount, search]);
  console.log("allUsers", allUsers)

  async function fetchAllUsersFunc(data) {
    setisLoading(true);
    try {
      const temp = {
        id: location.state.data.id,
        page: pageCount,
        limit: 8,
        search: search.trim(),
      };
      let res = await getAstrologerGallery_api(temp);
      if (res.data.status) {
        setallUsers(res.data.data.Gallary);
        setpageLength(res?.data?.data?.totalPages);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));

  async function deleteuserFunc() {
    setisLoading(true);
    let temp = {
      id: deleteId,
    };
    try {
      let res = await deleteAstrologerGallery_api(temp);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        setdeletePopup(false);
        fetchAllUsersFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  }

  const user_status = async (data) => {
    setisLoading(true);

    const fd = new FormData();
    fd.append("id", data.id);
    fd.append("status", data.status == true ? false : true);
    try {
      let res = await user_status_api(fd);
      console.log(res);

      if (res.data.status) {
        setisLoading(false);
        fetchAllUsersFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  };

  const userWalletFunc = (id) => {
    console.log(id)
    navigate("/add_wallet", {
      state: {
        pagetype: "Add",
        // data: row,
      },
    })
  }

  return (
    <div className="">
      <div className="beat_heading">
        <div className={s["user-list-heading"]}>
          <div className="user-list-title">
            <h3>Astrologer Gallery</h3>
          </div>
          <div className={s["user-list-search"]}>
            <div className={s["search-box"]}>
              <span style={{ paddingRight: "0.5rem" }}>
                <BiSearch size={23} />
              </span>
              <input
                type="text"
                spellCheck="false"
                onChange={(e) => {
                  setpageCount(1);
                  setsearch(e.target.value);
                }}
                placeholder="Search users by name.."
              />
            </div>
          </div>
        </div>
        <div className="beat_left" style={{ marginBottom: "1rem" }}>
          <div
            className={s["title"]}
            onClick={() =>
              navigate("/astrologer-gallery-add", {
                state: {
                  pagetype: "Add",
                  data: location.state.data
                },
              })
            }
          >
            <IoMdAdd /> Add
          </div>
        </div>
        {/* <div className={s["filter-container"]}>
          <div className={s["filter-left"]}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={4}>
                <div className="form-group">
                  <label for="exampleInputEmail1">Start Date</label>
                  <input type="date" placeholder="Role" className="form-control" value={startdate} onChange={(e) => setstartdate(e.target.value)} />
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="form-group">
                  <label for="exampleInputEmail1">End Date</label>
                  <input type="date" placeholder="Role" className="form-control" value={enddate} onChange={(e) => setenddate(e.target.value)} />
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="form-group">
                  <label for="exampleInputEmail1">Status</label>
                  <select className="form-control" id="exampleFormControlSelect1">
                    <option selected value="">
                      Select
                    </option>
                    <option value="Published">Active</option>
                    <option value="Published">InActive</option>
                  </select>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="filter-right">
            <div className={s["filter-btn"]} onClick={() => fetchAllUsersFunc()}>
              <span style={{ paddingRight: "2px" }}>
                <BiFilter size={20} />
              </span>
              Filter
            </div>
          </div>
          <div
            className={s["filter-btn"]}
            onClick={() => {
              setenddate();
              setstartdate();
            }}
          >
            Reset
          </div>
        </div> */}
      </div>
      <div className="beat_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>File</StyledTableCell>
              <StyledTableCell align="center">Type</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {allUsers.map((row) => (
              <StyledTableRow onClick={() => console.log()} key={row.id}>
                {row.src?.split(".")?.[row.src.split(".").length - 1] === "mp4" ? (
                  <StyledTableCell>
                    <video width="48px" height="32px">
                      <source src={row.src} type="video/mp4" />
                    </video>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell>{row.src ? <img style={{ height: "2rem", width: "3rem" }} src={row.src} alt="" /> : null}</StyledTableCell>
                )}
                <StyledTableCell>{row.type} </StyledTableCell>
                <StyledTableCell align="center">
                  {/* <CiEdit
                    onClick={() => {
                      navigate("/useradd", {
                        state: { pagetype: "Edit", data: row, },
                      })
                    }}
                    style={{
                      fontSize: "1rem",
                      color: "var(--clr-primary)",
                      marginRight: "0.5rem",
                      cursor: "pointer",
                    }}
                  />
                  <MdDelete
                    onClick={() => {
                      setdeletedialobbox(true);
                      setdeletename(row.user_name);
                      setdeleteId(row.id);
                    }}
                    style={{ fontSize: "1rem", color: "var(--clr-primary)", cursor: "pointer" }}
                  /> */}
                  <span className={s["astrologer-action-btn"]}>
                    <span
                      onClick={() => {
                        setdeletePopup(true);
                        setcurrentGroup(row);
                        setdeleteId(row.id);
                      }}
                    >
                      Delete
                    </span>
                  </span>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {allUsers.length <= 0 && <DataNotFound />}
        {allUsers?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => {setpageCount(value); localStorage.setItem("astrogalleryIndex",value)}} page={pageCount} />
          </div>
        )}
      </div>
      <Dialog open={deletePopup} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth="true" onClose={() => setdeletePopup(false)}>
        <DialogTitle className={s.dialog_title}>
          <div>Do you want to delete {currentGroup.name}?</div>
        </DialogTitle>
        <DialogContent className={s.cardpopup_content}>
          <div style={{ display: "flex", gap: "1rem" }}>
            <div className={s.employee_gl_popup} onClick={() => setdeletePopup(false)}>
              Cancel
            </div>
            <div className={s.employee_gl_popup_del} onClick={() => deleteuserFunc()}>
              Delete
            </div>
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Loder loading={isLoading} />
    </div >
  );
};

export default AstrologerGallery;
