import React from "react";
import ForgotPassword from "../components/Auth/ForgotPassword";

const ResetPassword = () => {
  return (
    <>
      <ForgotPassword />
    </>
  );
};

export default ResetPassword;
