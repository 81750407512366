import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

const getTestimonials = getBaseUrl() + "admin/getTestimonials";
const status_updateTestimonial = getBaseUrl() + "admin/updateTestimonial";
const deleteTestimonial = getBaseUrl() + "admin/deleteTestimonial";
const addTestimonial = getBaseUrl() + "admin/addTestimonial";
const updateTestimonial = getBaseUrl() + "admin/updateTestimonial";

export const updateTestimonial_api = async (data) => {
  let config = {
    method: "patch",
    url: updateTestimonial,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const addTestimonial_api = async (data) => {
  let config = {
    method: "post",
    url: addTestimonial,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const deleteTestimonial_api = async (data) => {
  let config = {
    method: "delete",
    url: deleteTestimonial,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const status_updateTestimonial_api = async (data) => {
  let config = {
    method: "patch",
    url: status_updateTestimonial,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const getTestimonials_api = async (data) => {
  let config = {
    method: "get",
    url: getTestimonials + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
