import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./astrologer.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import { BiFilter, BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import { IoMdAdd } from "react-icons/io";
import {
  deleteBanner_api,
  getBanners_api,
  statusUpdate_banner_api,
} from "../api/banner";
import {
  invoiceListing,
  bulkInvoiceListing,
  bulkGenerateInvoice,
} from "../api/astrologer";
import { DialogBox } from "../Astrologer/DialogBox";

const BulkInvoiceListing = () => {
  const [isLoading, setisLoading] = useState(false);
  const [allInvoices, setallInvoices] = useState([]);
  const [generateButton, setGenerateButton] = useState(true);
  const [pageCount, setpageCount] = useState(
    parseInt(localStorage?.getItem("listingIndex")) || 1
  );

  const [pageLength, setpageLength] = useState();

  async function fetchAstroAllInvoiceFunc() {
    setisLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 10,
        end_date: endDate,
      };
      let res = await bulkInvoiceListing(temp);

      if (res.status === 200) {
        console.log("----------res", res);

        setallInvoices(res?.data?.data);
        // setpageLength(res?.data?.data?.totalPages);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));

  const [endDate, setEndDate] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [ids, setIds] = useState([]);

  // Calculate yesterday's date
  const yesterday = new Date();
  const today = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  today.setDate(today.getDate());

  // Format the date as yyyy-mm-dd
  const yesterdayFormatted = yesterday.toISOString().split("T")[0];
  const todayDateFormatted = today.toISOString().split("T")[0];

  // Set the initial state to yesterday's date
  useEffect(() => {
    setEndDate(yesterdayFormatted);
  }, []);

  const handleOnchangeEndData = (e) => {
    setEndDate(e.target.value);
    fetchAstroAllInvoiceFunc();
  };

  const handleRowClick = (e, id) => {
    if (e.target.checked) {
      setIds([...ids, id]); // Add the ID to the list of selected IDs
    } else {
      setIds(ids.filter((selectedId) => selectedId !== id)); // Remove the ID from the list of selected IDs
    }
  };
  const handleGenerateInvoice = async () => {
    setGenerateButton(false);
    const payload = {
      end_date: endDate,
      astrologerIds: ids,
    };
    let res = await bulkGenerateInvoice(payload);
    if (res.status) {
      setGenerateButton(true);
      notificationHandler({ type: "success", msg: res.data.message });
      fetchAstroAllInvoiceFunc()
    } else {
      setGenerateButton(true);
      notificationHandler({ type: "danger", msg: res.data.message });
      fetchAstroAllInvoiceFunc()
    }
  };

  const setAllSelect = (e) => {
    setSelectAll(e.target.checked);
    if (!selectAll) {
      const astrologerIds = allInvoices?.map((entry) => entry.astrologer_id);
      setIds(astrologerIds);
    } else {
      setIds([]);
    }
  };

  useEffect(() => {
    fetchAstroAllInvoiceFunc();
  }, [endDate]);

  console.log("=======", endDate, selectAll, ids);

  return (
    <div className="">
      <div className="beat_heading">
        <div className={s["invoice-end-date"]}>
          <div>
            <h4>End Date</h4>
            <input
              type="date"
              id="start"
              name="trip-start"
              max={todayDateFormatted}
              value={endDate}
              onChange={(e) => handleOnchangeEndData(e)}
            />
            <div className={s["select-all"]}>
              <h6>select all</h6>
              <input
                id="select-all"
                name="select-all"
                type="checkbox"
                value={selectAll}
                onChange={(e) => setAllSelect(e)}
              />
            </div>
          </div>
          {ids?.length > 0 ? (
            <Tooltip
              title={
                generateButton === true
                  ? ""
                  : "Please wait!! Your request is being processed.."
              }
            >
              <div
                style={{
                  height: "40px",
                  cursor: generateButton === true ? "pointer" : "wait",
                  backgroundColor: generateButton === true ? "" : "gray",
                }}
                className={s["title"]}
                onClick={() =>
                  generateButton === true ? handleGenerateInvoice() : ""
                }
              >
                <IoMdAdd /> Generate Invoice{" "}
              </div>
            </Tooltip>
          ) : (
            <></>
          )}
        </div>
        <div className={s["user-list-heading"]}>
          <div className="user-list-title">
            <h3>Invoices</h3>
          </div>
          <div className={s["user-list-search"]}></div>
        </div>
        <div className="beat_left"></div>
      </div>
      <div className="beat_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">ID</StyledTableCell>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Phone Number</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Earning Amount</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allInvoices?.map((row, index) => (
              <StyledTableRow key={"innv-" + row.id}>
                <StyledTableCell align="center">
                  <div className={s["invoice-row-id"]}>
                    <input
                      id={`select-all-${row.astrologer_id}`}
                      name="select-all"
                      type="checkbox"
                      checked={ids.includes(row.astrologer_id)}
                      onChange={(e) => handleRowClick(e, row?.astrologer_id)}
                    />{" "}
                    <div>{row.astrologer_id}</div>
                  </div>
                </StyledTableCell>
                <StyledTableCell>{row?.astrologer_name}</StyledTableCell>
                <StyledTableCell align="center">{row.phone}</StyledTableCell>
                <StyledTableCell align="center">{row?.status}</StyledTableCell>
                <StyledTableCell align="center">
                  {row?.total_earning}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {allInvoices?.length <= 0 && <DataNotFound />}
        {/* {allInvoices?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination
              count={pageLength}
              size="large"
              style={{ color: "#D21903" }}
              onChange={(e, value) => {
                setpageCount(value);
                localStorage.setItem("listingIndex", value);
              }}
              page={pageCount}
            />
          </div>
        )} */}
      </div>
      <Loder loading={isLoading} />
    </div>
  );
};

export default BulkInvoiceListing;
