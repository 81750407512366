import React from 'react'
import BulkInvoiceListing from '../components/BulkInvoice/BulkInvoiceListing'

const BulkInvoice = () => {
  return (
    <div>
        <BulkInvoiceListing/>
    </div>
  )
}

export default BulkInvoice
