import React from "react";
import AddRole from "../components/Role/AddRole";
const AddRolePage = () => {
  return (
    <>
      <AddRole />
    </>
  );
};

export default AddRolePage;
