import { Dialog, DialogActions, DialogContent, DialogTitle, Pagination } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { BiFilter, BiSearch } from "react-icons/bi";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Loder from "../../Loder/Loder";
import { notificationHandler } from "../../utils/Notification";
import DataNotFound from "../ErrorPage/DataNotFound";
import { status_update_categories_api } from "../api/category";
import { deletePujaAddons, getPujaAddons } from "../api/pujaAddons";
import { deletePujaCategory } from "../api/pujaCategory";
import s from "./Puja.module.css";

const AddOns = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [addons, setAddons] = useState([]);
  const [pageCount, setpageCount] = useState(parseInt(localStorage?.getItem("catgIndex")) || 1);
  const [deleteId, setDeleteId] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [currentGroup, setCurrentGroup] = useState({});
  const [pageLength, setPageLength] = useState();
  const [search, setsearch] = useState("");

  useEffect(() => {
    fetchAddons();
  }, [pageCount, search]);

  async function fetchAddons() {
    setIsLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
      };
      let res = await getPujaAddons(temp);
      if (res.data.status) {
        setAddons(res?.data?.data.addons);
        setPageLength(res?.data?.data?.totalPages);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));

  async function deleteCategoryFunc() {
    setIsLoading(true);
    let temp = {
      id: deleteId,
    };
    try {
      let res = await deletePujaAddons(temp);
      if (res.data.status) {
        setIsLoading(false);
        setDeletePopup(false);
        fetchAddons();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setIsLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  }

  const addons_status = async (data) => {
    setIsLoading(true);
    let temp = {
      id: data.id,
      status: data.status == true ? false : true,
    };
    try {
      let res = await status_update_categories_api(temp);
      console.log(res);
      if (res.data.status) {
        setIsLoading(false);
        fetchAddons();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setIsLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  };

  return (
    <div className="">
      <div className={s["beat_heading"]}>
        <div className={s["user-list-heading"]}>
          <div className="beat_left">
          <div
            className={s["title"]}
            onClick={() =>
              navigate("/add-puja-addons", {
                state: {
                  pagetype: "Add",
                },
              })
            }
          >
            <IoMdAdd /> Add AddOns
          </div>
          </div>
          <div className={s["user-list-search"]}>
            <div className={s["search-box"]}>
              <span style={{ paddingRight: "0.5rem" }}>
                <BiSearch size={23} />
              </span>
              <input
                type="text"
                spellCheck="false"
                onChange={(e) => {
                  setpageCount(1);
                  setsearch(e.target.value);
                }}
                placeholder="Search by title..."
              />
            </div>
          </div>
        </div>
     
      </div>
      <div className="beat_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Image</StyledTableCell>
              <StyledTableCell align="center">Title</StyledTableCell>
              <StyledTableCell align="center">Description</StyledTableCell>
              <StyledTableCell align="center">Price</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {addons?.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell>
                  {row.product_img ? <img style={{ height: "2rem", width: "3rem" }} src={row.product_img} alt="" /> : null}
                </StyledTableCell>

                <StyledTableCell align="center">{row.name} </StyledTableCell>
                <StyledTableCell align="center">{row.description}</StyledTableCell>
                <StyledTableCell align="center">{row.price}</StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    style={{ cursor: "pointer" }}
                    // onClick={() => addons_status(row)}
                    className={`${row.status === 'published' ? s.published : row.status === 'draft' ? s.draft : s.deleted}`}
                  >
                    {row.status.toUpperCase()}
                  </div>
                </StyledTableCell>

                <StyledTableCell align="center">
                  <div className="astrologer-action-btn">
                    <span
                      onClick={() => {
                        navigate("/add-puja-addons", {
                          state: {
                            pagetype: "Edit",
                            data: row,
                          },
                        })
                      }}
                    >
                      Edit
                    </span>
                    {/* <span
                      onClick={() => {
                        setDeletePopup(true);
                        setCurrentGroup(row);
                        setDeleteId(row.id);
                      }}
                    >
                      Delete
                    </span> */}
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {addons?.length <= 0 && <DataNotFound />}
        {addons?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => {setpageCount(value); localStorage.setItem("catgIndex",value)}} page={pageCount} />
          </div>
        )}
      </div>

      <Dialog open={deletePopup} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth={true} onClose={() => setDeletePopup(false)}>
        <DialogTitle className={s.dialog_title}>
          <div>Do you want to delete {currentGroup.category_name}?</div>
        </DialogTitle>
        <DialogContent className={s.cardpopup_content}>
          <div style={{ display: "flex", gap: "1rem" }}>
            <div className={s.employee_gl_popup} onClick={() => setDeletePopup(false)}>
              Cancel
            </div>
            <div className={s.employee_gl_popup_del} onClick={() => deleteCategoryFunc()}>
              Delete
            </div>
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Loder loading={isLoading} />
    </div>
  );
};

export default AddOns;
