import React from "react";
import BannerList from "../components/Banner/BannerList";

const Banner = () => {
  return (
    <div>
      <BannerList />
    </div>
  );
};

export default Banner;
