import { Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FiUsers } from "react-icons/fi";
import s from "./dashboard.module.css";
import Graph from "./Graph";
import Graph1 from "./Graph1";
import { dashboard_api } from "../api/dashboardAPI";
import Loder from "../../Loder/Loder";
const Home = () => {
  const [isLoading, setisLoading] = useState(false);
  const [dashboardData, setdashboardData] = useState()
  useEffect(() => {
    getDashboardDataFunc()
  }, [])
  console.log("dashboardData", dashboardData)

  async function getDashboardDataFunc() {
    setisLoading(true);

    const temp = {};
    let res = await dashboard_api(temp);
    if (res.data.status) {
      setdashboardData(res.data.data);
      setisLoading(false);
    } else {
      setisLoading(false);
      console.log("Some Error!");
    }
  }

  const cardArray = [
    {
      title: "Total Astrologer",
      count: "2294",
    },
    {
      title: "Total Users",
      count: "1584",
    },
    {
      title: "Total Consultations",
      count: "1584",
    },
    {
      title: "New Users",
      count: "1814",
    },
  ];
  return (
    <>
      {dashboardData && (
        <div style={{ paddingInline: "1rem" }}>
          <div className="dashboard-section">
            <div className={s["dashboard-header-container"]}>
              <Card className={s["dashbord-content"]}>
                <div class={s["dashboard-main-card"]}>
                  <div class={s["dashboard-card-content"]}>
                    <div class={s["card-title"]}>
                      <h6>Total Astrologer</h6>
                      <h4>{dashboardData?.TotalAstro}</h4>
                    </div>
                  </div>
                </div>
              </Card>
              <Card className={s["dashbord-content"]}>
                <div class={s["dashboard-main-card"]}>
                  <div class={s["dashboard-card-content"]}>
                    <div class={s["card-title"]}>
                      <h6>Total Users</h6>
                      <h4>{dashboardData?.TotalUser}</h4>
                    </div>
                  </div>
                </div>
              </Card>
              <Card className={s["dashbord-content"]}>
                <div class={s["dashboard-main-card"]}>
                  <div class={s["dashboard-card-content"]}>
                    <div class={s["card-title"]}>
                      <h6>Total Consultations</h6>
                      <h4>{dashboardData?.consultationTime}</h4>
                    </div>
                  </div>
                </div>
              </Card>
              <Card className={s["dashbord-content"]}>
                <div class={s["dashboard-main-card"]}>
                  <div class={s["dashboard-card-content"]}>
                    <div class={s["card-title"]}>
                      <h6>New Users</h6>
                      <h4>{dashboardData?.NewUsers24Hrs}</h4>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div className={s["graph-container"]}>
            <div className={s["graph-content"]}>
              <div className="graph-left" style={{ width: "70%" }}>
                <Graph1 />
              </div>
              <div className="graph-right" style={{ width: "30%" }}>
                <Graph />
              </div>
            </div>
          </div>
        </div>
      )}
      <Loder loading={isLoading} />
    </>
  );
};

export default Home;
