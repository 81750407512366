import React from "react";
import AddUser from "../components/Users/AddUser";
const UserAdd = () => {
  return (
    <>
      <AddUser />
    </>
  );
};

export default UserAdd;
