import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

export const dashboard_api = async (data) => {
  let config = {
    method: "post",
    url: getBaseUrl() + "admin/dashboardData",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
}