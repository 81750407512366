import React from "react";
import BannerAdd from "../components/Banner/BannerAdd";

const AddBanner = () => {
  return (
    <div>
      <BannerAdd />
    </div>
  );
};

export default AddBanner;
