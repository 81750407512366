import React from "react";
import ProfilePage from "../components/Profile/ProfilePage";
const profile = () => {
  return (
    <>
      <ProfilePage />
    </>
  );
};

export default profile;
