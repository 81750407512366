import { Card, Grid, Pagination } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import SampleFile from "../../assets/files/BulkCashback_Addition_Withdrawal_Sample.csv";
import { notificationHandler } from "../../utils/Notification";
import DataNotFound from "../ErrorPage/DataNotFound";
import s from "../Users/user.module.css";
import { getPromotionalUploads, updateBonusAmount_api, updateWallet_api } from "../api/walletAPI";
import Loder from "../../Loder/Loder";


const Wallet = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setisLoading] = useState(false);
    const [type, settype] = useState("credit");
    const [file, setfile] = useState(null);
    const [wbonasAmount, setWbonasAmount] = useState()
    const [pageCount, setpageCount] = useState(1);
    const [pageLength, setpageLength] = useState();
    const [ promotionalUploadsData, setPromotionalUploadsData ] = useState()
    const pagetype = location?.state?.pagetype;

    useEffect(() => {
        getPromotionalUploadsFunc();
    }, [pageCount]);


    async function getPromotionalUploadsFunc() {
        setisLoading(true);
        try {
          const temp = {
            page: pageCount,
            limit: 5,
          };
          let res = await getPromotionalUploads(temp);
          if (res.data.status) {
            setPromotionalUploadsData(res?.data?.data);
            setpageLength(res?.data?.totalPages);
            setisLoading(false);
          } else {
            setisLoading(false);
            console.log("status false!");
          }
        } catch (error) {
          console.log(error);
        }
      }    

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: "var(--clr-theme)",
          color: theme.palette.common.white,
          fontWeight: "bold",
          borderRight: "1px solid #fff",
          overflow: "hidden",
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
          border: "none",
          borderLeft: "2px solid #00000011",
          "&:last-child": {
            borderRight: "2px solid #00000011",
          },
        },
      }));
    
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        borderBottom: "2px solid #00000011",
      }));

    const updateBonusAmountFunc = async () => {
        let res = await updateBonusAmount_api({ bonusAmt: wbonasAmount });
        if (res.data.status) {
            notificationHandler({ type: "success", msg: res.data.message });
        } else {
            notificationHandler({ type: "danger", msg: "Error!" });
        }
    }

    const walletFunc = async () => {
        console.log(type)

        const fd = new FormData();
        fd.append("type", type);
        fd.append("csv", file);
        let res = await updateWallet_api(fd);
        if (res.data.status) {
            navigate("/users");
            notificationHandler({ type: "success", msg: res.data.message });
        } else {
            notificationHandler({ type: "danger", msg: "Error!" });
            console.log("error");
        }

        // if (pagetype == "Edit") {
        //     const fd = new FormData();
        //     fd.append("id", location?.state?.data?.id);
        //     fd.append("type", type);
        //     fd.append("banner_image", file);
        //     fd.append("url", url);
        //     try {
        //         let res = await updateBanner_api(fd);
        //         if (res.data.status) {
        //             navigate("/banner-list");
        //             notificationHandler({ type: "success", msg: res.data.message });
        //         } else {
        //             notificationHandler({ type: "success", msg: res.data.message });
        //         }
        //     } catch (error) {
        //         notificationHandler({ type: "danger", msg: error.message });
        //         console.log(error);
        //     }
        // }
    };

    return (
        <>
            <div className="">
                <div className={s["user-list-heading"]}>
                    <div className="user-list-title">
                        <h3>Wallet</h3>
                    </div>
                    <div className={s["sample_csv_btn"]}>
                        <a href={SampleFile} download>Download Sample File</a>
                    </div>
                </div>
                <Card className={s["admin_container"]} style={{ marginBottom: "1rem" }} >
                    <h5>Welcome Bonus Amount</h5>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1"></label>
                                <div className="mr-2">
                                    <input type="text" className="form-control" value={wbonasAmount} onChange={(e) => setWbonasAmount(e.target.value)} />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1"></label>
                                <div className="mr-2">
                                    <div style={{ width: "25%" }} onClick={updateBonusAmountFunc}>
                                        <Custombutton>SAVE</Custombutton>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Card>
                <Card className={s["admin_container"]}>
                    {/* <div className={s["title"]} onClick={() => navigate(-1)}>
                        <BiArrowBack />
                        Back
                    </div> */}
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Select Type</label>
                                <div className="mr-2">
                                    <select className="form-control" value={type} onChange={(e) => settype(e.target.value)} >
                                        <option value="credit">Deposit</option>
                                        <option value="debit">Withdraw</option>
                                        <option value="refund">Refund</option>
                                    </select>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Select File</label>
                                <div className="mr-2">
                                    {/* <input type="file" className="form-control" name="img" placeholder="" accept="image/*" /> */}
                                    <input type="file" className="form-control" accept=".csv" onChange={(e) => setfile(e.target.files[0])} />
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    <div className={s["form-login-btn"]} onClick={walletFunc}>
                        <Custombutton>Submit</Custombutton>
                    </div>
                </Card>

                <div className="beat_table" style={{marginTop: "1rem"}}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">S. No.</StyledTableCell>
              <StyledTableCell align="center">Date & Time</StyledTableCell>
              <StyledTableCell align="center">File Name </StyledTableCell>
              <StyledTableCell align="center">Bulk Upload/Withdrawal</StyledTableCell>
              <StyledTableCell align="center">Count of Users</StyledTableCell>
              <StyledTableCell align="center">Total Amount</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {promotionalUploadsData?.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell>
                  {row.idx}
                </StyledTableCell>
                <StyledTableCell align="center">{
                  new Date(row.created_at).toLocaleDateString("en-IN", {
                    year: "numeric",
                    month: "numeric",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                }</StyledTableCell>
                <StyledTableCell align="center">
                 <a href={row.file_url} download><u>{row.file_name}</u></a>
                 </StyledTableCell>
                <StyledTableCell align="center" style={{textTransform: "capitalize"}}>{row.upload_type} </StyledTableCell>
                <StyledTableCell align="center">{row.user_count} </StyledTableCell>
                <StyledTableCell align="center">{row.total_amount} </StyledTableCell>
                <StyledTableCell align="center" style={{textTransform: "capitalize"}}>{row.status} </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {promotionalUploadsData?.length <= 0 && <DataNotFound />}
        {promotionalUploadsData?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination 
            count={pageLength} 
            size="large" style={{ color: "#D21903" }} 
            onChange={(e, value) => setpageCount(value)} 
            page={pageCount} 
            />
          </div>
        )}
      </div>
            </div>
            <Loder loading={isLoading} />
        </>
    );
};

export default Wallet;
