import React, { useEffect, useState } from "react";
import { Card, CircularProgress, Grid, Box } from "@mui/material";
import s from "./AstrologerSlabAdd.module.css";
import Custombutton from "../../Common/Custombutton";
import {
  addAstrologerSlab_api,
  deleteAstrologerSlab_api,
  getAstrologerSlab_api,
} from "../api/astrologerSlab";
import { notificationHandler } from "../../utils/Notification";
import { MdOutlineDeleteOutline } from "react-icons/md";

const AstrologerSlabAdd = () => {
  const [chatInput, setChatInput] = useState();
  const [chatInputSlab, setChatInputSlab] = useState([]);
  const [callInput, setCallInput] = useState();
  const [callInputSlab, setCallInputSlab] = useState([]);
  const [offerInput, setOfferInput] = useState();
  const [offerInputSlab, setOfferInputSlab] = useState([]);
  const [inputError, setInputError] = useState({
    chatInput: "",
    callInput: "",
    offerInput: "",
  });

  const [chatSlabArray, setChatSlabArray] = useState([]);
  const [callSlabArray, setCallSlabArray] = useState([]);
  const [offerSlabArray, setOfferSlabArray] = useState([]);
  const [isloading, setisloading] = useState(false);

  function onHandleChangeChat(e) {
    e.preventDefault();
    setChatInput(e.target.value);
  }
  function onHandleChangeCall(e) {
    e.preventDefault();
    setCallInput(e.target.value);
  }
  function onHandleChangeOffer(e) {
    e.preventDefault();
    setOfferInput(e.target.value);
  }

  function setSlab(type) {
    if (type === "chat") {
      const chatArray = [];
      for (let i = 0; i < chatInput; i++) {
        chatArray.push({
          maxLimit: 0,
        });
      }
      setChatInputSlab((prevChatInputSlab) => [
        ...prevChatInputSlab,
        ...chatArray,
      ]);
      setChatSlabArray((prevChatSlabArray) => [
        ...prevChatSlabArray,
        ...chatArray,
      ]);
    } else if (type === "call") {
      const callArray = [];
      for (let j = 0; j < callInput; j++) {
        callArray.push({
          maxLimit: 0,
        });
      }
      setCallInputSlab((prevCallInputSlab) => [
        ...prevCallInputSlab,
        ...callArray,
      ]);
      setCallSlabArray((prevCallSlabArray) => [
        ...prevCallSlabArray,
        ...callArray,
      ]);
    } else if (type === "offer") {
      const offer = [];
      for (let j = 0; j < offerInput; j++) {
        offer.push({
          maxLimit: 0,
        });
      }
      setOfferInputSlab((prevOfferInputSlab) => [
        ...prevOfferInputSlab,
        ...offer,
      ]);
      setOfferSlabArray((prevOfferSlabArray) => [
        ...prevOfferSlabArray,
        ...offer,
      ]);
    }
  }

  async function getSlabData() {
    const res = await getAstrologerSlab_api();
    if (res?.data?.status) {
      var chatPricingSlabArray = [];
      var callPricingSlabArray = [];
      var offerPricingSlabArray = [];
      for (let i = 0; i < res?.data?.priceSlabs?.length; i++) {
        if (res?.data?.priceSlabs?.[i]?.consult_type === "chat") {
          chatPricingSlabArray.push({
            id: res?.data?.priceSlabs?.[i]?.id,
            maxLimit: res?.data?.priceSlabs?.[i]?.slab_limit,
          });
        } else if (res?.data?.priceSlabs?.[i]?.consult_type === "call") {
          callPricingSlabArray.push({
            id: res?.data?.priceSlabs?.[i]?.id,
            maxLimit: res?.data?.priceSlabs?.[i]?.slab_limit,
          });
        }else if (res?.data?.priceSlabs?.[i]?.slab_type === "recharge") {
          console.log("reachageSablb",res?.data?.priceSlabs?.[i]?.id)
          offerPricingSlabArray.push({
            id: res?.data?.priceSlabs?.[i]?.id,
            maxLimit: res?.data?.priceSlabs?.[i]?.slab_limit,
          });
        }
      }

      setChatInputSlab(chatPricingSlabArray);
      setCallInputSlab(callPricingSlabArray);
      setOfferInputSlab(offerPricingSlabArray);
      setChatSlabArray(chatPricingSlabArray);
      setCallSlabArray(callPricingSlabArray);
      setOfferSlabArray(offerPricingSlabArray);
    }
  }

  // chats function
  function limitChatValueReturn(id) {
    if (chatSlabArray.length == 0) return undefined;
    const matchObject = chatSlabArray?.filter((data) => data.id == id);
    return matchObject[0];
  }
  // update maxLimit
  function updateChatMaxLimit(id, value) {
    const updatedData = chatSlabArray.map((item) => {
      if (item.id === id) {
        return { ...item, maxLimit: Number(value) };
      }
      return item;
    });
    setChatSlabArray(updatedData);
  }

  function setChatMaxLimit(idx, value) {
    var copyObj = [...chatSlabArray];
    copyObj[idx].maxLimit = Number(value);
    setChatSlabArray(copyObj);
  }

  async function deleteSlab(id) {
    try {
      const res = await deleteAstrologerSlab_api(id);
      if (res?.data?.status) {
        notificationHandler({ type: "success", msg: res.data.message });
        getSlabData();
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
    }
  }

  // call function
  function limitCallValueReturn(id) {
    if (callSlabArray.length == 0) return undefined;
    const matchObject = callSlabArray?.filter((data) => data.id == id);
    return matchObject[0];
  }
  // update maxLimit
  function updateCallMaxLimit(id, value) {
    const updatedData = callSlabArray.map((item) => {
      if (item.id === id) {
        return { ...item, maxLimit: Number(value) };
      }
      return item;
    });
    setCallSlabArray(updatedData);
  }

  function setCallMaxLimit(idx, value) {
    var copyObj = [...callSlabArray];
    copyObj[idx].maxLimit = Number(value);
    setCallSlabArray(copyObj);
  }

  // functions for offers
  function limitOfferValueReturn(id) {
    if (offerSlabArray.length == 0) return undefined;
    const matchObject = offerSlabArray?.filter((data) => data.id == id);
    return matchObject[0];
  }
  // update maxLimit
  function updateOfferMaxLimit(id, value) {
    const updatedData = offerSlabArray.map((item) => {
      if (item.id === id) {
        return { ...item, maxLimit: Number(value) };
      }
      return item;
    });
    setOfferSlabArray(updatedData);
  }

  function setOfferMaxLimit(idx, value) {
    var copyObj = [...offerSlabArray];
    copyObj[idx].maxLimit = Number(value);
    setOfferSlabArray(copyObj);
  }

  // api call for slab add
  async function submitSlab() {
    const containsChatMaxLimitZero = chatSlabArray.some(
      (item) => item.maxLimit === 0
    );
    const containsCallMaxLimitZero = callSlabArray.some(
      (item) => item.maxLimit === 0
    );
    console.log("======", containsCallMaxLimitZero || containsChatMaxLimitZero);
    if (containsCallMaxLimitZero || containsChatMaxLimitZero) {
      notificationHandler({
        type: "danger",
        msg: "Slab max limit must not be zero",
      });
    } else {
      const payload = {
        callSlabCount: callSlabArray.length,
        chatSlabCount: chatSlabArray.length,
        callSlabArray: callSlabArray,
        chatSlabArray: chatSlabArray,
        offerSlabCount: 0,
        offerSlabArray: [],
      };
      console.log("apiData", payload);

      try {
        let res = await addAstrologerSlab_api(payload);
        if (res.data.status) {
          notificationHandler({ type: "success", msg: res.data.message });
          getSlabData();
        } else {
          notificationHandler({ type: "danger", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
      }
    }
  }
  async function submitSlabOffer() {
    const containsChatMaxLimitZero = offerSlabArray.some(
      (item) => item.maxLimit === 0
    );
    console.log("======", containsChatMaxLimitZero);
    if (containsChatMaxLimitZero) {
      notificationHandler({
        type: "danger",
        msg: "Offer Slab max limit must not be zero",
      });
    } else {
      const payload = {
        callSlabCount: 0,
        chatSlabCount: 0,
        callSlabArray: [],
        chatSlabArray: [],
        offerSlabCount: offerSlabArray.length,
        offerSlabArray: offerSlabArray,
      };
      console.log("apiData", payload);

      try {
        let res = await addAstrologerSlab_api(payload);
        if (res.data.status) {
          notificationHandler({ type: "success", msg: res.data.message });
          getSlabData();
        } else {
          notificationHandler({ type: "danger", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
      }
    }
  }

  useEffect(() => {
    getSlabData();
  }, []);

  console.log("error", chatSlabArray, callSlabArray,offerSlabArray);

  return (
    <>
      <div className={s["user-list-title"]}>
        <h3>Astrologers: Slabs </h3>
      </div>
      <div className={s["container"]}>
        <Box className={s["chat-parent-container"]}>
          <div className={s["chat-container"]}>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Chat Slab</label>
                <input
                  style={{ width: "300px", height: "46px" }}
                  type="number"
                  className="form-control"
                  placeholder="Enter no of chat slab"
                  name="name"
                  value={chatInput}
                  onChange={onHandleChangeChat}
                />
              </div>
              <Box
                sx={{
                  color: "red",
                  fontSize: "12px",
                  ml: "10px",
                  position: "absolute",
                }}
              >
                {inputError.chatInput !== "" ? inputError.chatInput : ""}
              </Box>
            </Grid>
            <Grid sx={{ mt: "10px" }} item xs={4}>
              <div
                className={s["form-login-btn"]}
                onClick={() => setSlab("chat")}
              >
                <Custombutton>
                  {isloading ? (
                    <CircularProgress
                      style={{ width: "20px", height: "20px", color: "#fff" }}
                    />
                  ) : (
                    "Create chat slab"
                  )}
                </Custombutton>
              </div>
            </Grid>
          </div>
          {/* dynamic field to range chat */}
          {chatInputSlab.length > 0 ? (
            <Grid sx={{ mt: "50px" }} item xs={4}>
              {chatInputSlab.map((data, idx) => {
                return (
                  <div className={s["slab-range-field"]} key={idx}>
                    <label for={`chat_start_range_${idx + 1}`}>
                      {idx + 1}st slab Upto {data?.maxLimit}
                    </label>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        gap: "20px",
                        alignItems: "center",
                        position: "relative",
                      }}
                      className={`chat_start_range_${idx + 1}`}
                    >
                      <input
                        style={{ height: "46px" }}
                        type="number"
                        className="form-control"
                        placeholder={`${idx + 1}st slab`}
                        name={`chat_start_range_${idx + 1}`}
                        id={`chat_start_range_${idx + 1}`}
                        value={
                          data?.id
                            ? limitChatValueReturn(data?.id)?.maxLimit
                            : chatSlabArray[idx].maxLimit
                        }
                        onChange={(e) => {
                          data.id
                            ? updateChatMaxLimit(data.id, e.target.value)
                            : setChatMaxLimit(idx, e.target.value);
                        }}
                      />
                      <MdOutlineDeleteOutline
                        style={{
                          cursor: "pointer",
                        }}
                        color={"red"}
                        onClick={() => deleteSlab(data.id)}
                        size={30}
                      />
                    </Box>
                  </div>
                );
              })}
            </Grid>
          ) : (
            <></>
          )}
        </Box>
        <Box className={s["call-parent-container"]}>
          <div className={s["call-container"]}>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Call Slab</label>
                <input
                  style={{ width: "300px", height: "46px" }}
                  type="number"
                  className="form-control"
                  placeholder="Enter no of  call slab"
                  name="name"
                  value={callInput}
                  onChange={onHandleChangeCall}
                />
              </div>
              <Box
                sx={{
                  color: "red",
                  fontSize: "12px",
                  ml: "10px",
                  position: "absolute",
                }}
              >
                {inputError.callInput !== "" ? inputError.callInput : ""}
              </Box>
            </Grid>
            <Grid sx={{ mt: "10px" }} item xs={4}>
              <div
                className={s["form-login-btn"]}
                onClick={() => setSlab("call")}
              >
                <Custombutton>
                  {isloading ? (
                    <CircularProgress
                      style={{ width: "20px", height: "20px", color: "#fff" }}
                    />
                  ) : (
                    "Create call Slab"
                  )}
                </Custombutton>
              </div>
            </Grid>
          </div>
          {/* dynamic field to range call */}
          {callInputSlab.length > 0 ? (
            <Grid sx={{ mt: "50px" }} item xs={4}>
              {callInputSlab.map((data, idx) => {
                return (
                  <div className={s["slab-range-field"]} key={idx}>
                    <label for={`call_start_range_${idx + 1}`}>
                      {idx + 1}st slab Upto {data.maxLimit}
                    </label>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        gap: "20px",
                        alignItems: "center",
                        position: "relative",
                      }}
                      className={`call_start_range_${idx + 1}`}
                    >
                      <input
                        style={{ height: "46px" }}
                        type="number"
                        className="form-control"
                        placeholder={`${idx + 1}st slab`}
                        name={`call_start_range_${idx + 1}`}
                        id={`call_start_range_${idx + 1}`}
                        value={
                          data?.id
                            ? limitCallValueReturn(data?.id)?.maxLimit
                            : callSlabArray[idx].maxLimit
                        }
                        onChange={(e) => {
                          data.id
                            ? updateCallMaxLimit(data.id, e.target.value)
                            : setCallMaxLimit(idx, e.target.value);
                        }}
                      />
                      <MdOutlineDeleteOutline
                        style={{
                          cursor: "pointer",
                        }}
                        color={"red"}
                        onClick={() => deleteSlab(data.id)}
                        size={30}
                      />
                    </Box>
                  </div>
                );
              })}
            </Grid>
          ) : (
            <></>
          )}
        </Box>
      </div>
      {/* submit button */}
      {chatInputSlab.length > 0 && callInputSlab.length > 0 ? (
        <Box
          sx={{
            mt: "20px",
            px: "30px",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <div className={s["form-login-btn"]} onClick={() => submitSlab()}>
            <Custombutton>
              {isloading ? (
                <CircularProgress
                  style={{ width: "20px", height: "20px", color: "#fff" }}
                />
              ) : (
                "Submit Slab"
              )}
            </Custombutton>
          </div>
        </Box>
      ) : (
        <></>
      )}
      <hr style={{
        marginTop:"50px",
        marginBottom:"30px"
       
      }}/>
      <div className={s["user-list-title"]}>
        <h3>Recharge Offers: Slabs </h3>
      </div>
      <div className={s["container"]}>
        <Box className={s["call-parent-container"]}>
          <div className={s["call-container"]}>
            <Grid item xs={4}>
              <div className="form-group">
                <label for="exampleInputEmail1">Offer Slab</label>
                <input
                  style={{ width: "300px", height: "46px" }}
                  type="number"
                  className="form-control"
                  placeholder="Enter no of offer slab"
                  name="name"
                  value={offerInput}
                  onChange={onHandleChangeOffer}
                />
              </div>
              <Box
                sx={{
                  color: "red",
                  fontSize: "12px",
                  ml: "10px",
                  position: "absolute",
                }}
              >
                {inputError.offerInput !== "" ? inputError.offerInput : ""}
              </Box>
            </Grid>
            <Grid sx={{ mt: "10px" }} item xs={4}>
              <div
                className={s["form-login-btn"]}
                onClick={() => setSlab("offer")}
              >
                <Custombutton>
                  {isloading ? (
                    <CircularProgress
                      style={{ width: "20px", height: "20px", color: "#fff" }}
                    />
                  ) : (
                    "Create offer Slab"
                  )}
                </Custombutton>
              </div>
            </Grid>
          </div>
          {/* dynamic field to range call */}
          {offerInputSlab.length > 0 ? (
            <Grid sx={{ mt: "50px" }} item xs={4}>
              {offerInputSlab.map((data, idx) => {
                return (
                  <div className={s["slab-range-field"]} key={idx}>
                    <label for={`call_start_range_${idx + 1}`}>
                      {idx + 1}st slab Upto {data.maxLimit}
                    </label>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        gap: "20px",
                        alignItems: "center",
                        position: "relative",
                      }}
                      className={`call_start_range_${idx + 1}`}
                    >
                      <input
                        style={{ height: "46px" }}
                        type="number"
                        className="form-control"
                        placeholder={`${idx + 1}st slab`}
                        name={`call_start_range_${idx + 1}`}
                        id={`call_start_range_${idx + 1}`}
                        value={
                          data?.id
                            ? limitOfferValueReturn(data?.id)?.maxLimit
                            : offerSlabArray[idx].maxLimit
                        }
                        onChange={(e) => {
                          data.id
                            ? updateOfferMaxLimit(data.id, e.target.value)
                            : setOfferMaxLimit(idx, e.target.value);
                        }}
                      />
                      <MdOutlineDeleteOutline
                        style={{
                          cursor: "pointer",
                        }}
                        color={"red"}
                        onClick={() => deleteSlab(data.id)}
                        size={30}
                      />
                    </Box>
                  </div>
                );
              })}
              <div
                style={{
                  marginTop:"20px",
                  width:"fit-content"
                }}
                className={s["form-login-btn"]}
                onClick={() => submitSlabOffer()}
              >
                <Custombutton>
                  {isloading ? (
                    <CircularProgress
                      style={{ width: "20px", height: "20px", color: "#fff" }}
                    />
                  ) : (
                    "Submit Offer Slab"
                  )}
                </Custombutton>
              </div>
            </Grid>
          ) : (
            <></>
          )}
        </Box>
      </div>
    </>
  );
};

export default AstrologerSlabAdd;
