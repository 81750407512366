import React from "react";
import MainPage from "../components/Users/MainPage";

const Users = () => {
  return (
    <>
      <MainPage />
    </>
  );
};

export default Users;
