import React from "react";
import CategoryList from "../components/Category/CategoryList";

const Category = () => {
  return (
    <div>
      <CategoryList />
    </div>
  );
};

export default Category;
