import React from "react";
import RoleListPage from "../components/Role/RoleListPage";

const RollList = () => {
  return (
    <>
      <RoleListPage />
    </>
  );
};

export default RollList;
