import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./banner.module.css";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogActions, DialogTitle, DialogContent, Pagination } from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import { BiFilter, BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import ImagePreview from "./ImagePreview";
import { IoMdAdd } from "react-icons/io";
import { deleteBanner_api, getBanners_api, statusUpdate_banner_api } from "../api/banner";
import { getAstrologerSlab_api } from "../api/astrologerSlab";

const BannerList = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [allbanner, setallbanner] = useState([]);
  const [pageCount, setpageCount] = useState(parseInt(localStorage?.getItem("bannerIndex")) || 1);
  const [deleteId, setdeleteId] = useState("");
  const [deletePopup, setdeletePopup] = useState(false);
  const [offerSlab, setOfferSlab] = useState([]);
  const [currentGroup, setcurrentGroup] = useState({});
  const [bannerImagePreview, setBannerImagePreview] = useState("");
  const [pageLength, setpageLength] = useState();
  const [search, setsearch] = useState("");

  useEffect(() => {
    fetchallbannerFunc();
  }, [pageCount, search]);

  useEffect(() => {
    getOffersSlabs();
  }, []);

  async function getOffersSlabs() {
    const res = await getAstrologerSlab_api();
    var offerPricingSlabArray = [];
    if (res?.data?.status) {
      for (let i = 0; i < res?.data?.priceSlabs?.length; i++) {
        if (res?.data?.priceSlabs?.[i]?.slab_type === "recharge") {
          offerPricingSlabArray.push({
            id: res?.data?.priceSlabs?.[i]?.id,
            maxLimit: res?.data?.priceSlabs?.[i]?.slab_limit,
          });
        }
      }
      setOfferSlab(offerPricingSlabArray);
    }
  }

  async function fetchallbannerFunc() {
    setisLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 10,
        search: search.trim(),
      };
      let res = await getBanners_api(temp);
      if (res.data.status) {
        setallbanner(res?.data?.data?.banners);
        setpageLength(res?.data?.data?.totalPages);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));


  async function deleteuserFunc() {
    setisLoading(true);
    let temp = {
      id: deleteId,
    };
    try {
      let res = await deleteBanner_api(temp);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        setdeletePopup(false);
        fetchallbannerFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  }

  const banner_status = async (data) => {
    setisLoading(true);
    const fd = new FormData();
    fd.append("id", data.id);
    fd.append("status", data.status == true ? false : true);
    try {
      let res = await statusUpdate_banner_api(fd);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        fetchallbannerFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  };


  return (
    <div className="">
      <div className="beat_heading">
        <div className={s["user-list-heading"]}>
          <div className="user-list-title">
            <h3>Banner List</h3>
          </div>
          <div className={s["user-list-search"]}>
            <div className={s["search-box"]}>
              <span style={{ paddingRight: "0.5rem" }}>
                <BiSearch size={23} />
              </span>
              <input
                type="text"
                spellCheck="false"
                onChange={(e) => {
                  setpageCount(1);
                  setsearch(e.target.value);
                }}
                placeholder="Search by name..."
              />
            </div>
            <div className={s["filter-btn"]}>
              <span style={{ paddingRight: "2px" }}>
                <BiFilter size={20} />
              </span>
              Filter
            </div>
          </div>
        </div>
        <div className="beat_left">
          <div
            className={s["title"]}
            onClick={() =>
              navigate("/add-banner", {
                state: {
                  pagetype: "Add",
                  slabData: offerSlab,
                },
              })
            }
          >
            <IoMdAdd /> Banner
          </div>
        </div>
      </div>
      <div className="beat_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Image</StyledTableCell>
              <StyledTableCell align="center">ID</StyledTableCell>
              <StyledTableCell align="center">Category Type</StyledTableCell>
              <StyledTableCell align="center">Start Date</StyledTableCell>
              <StyledTableCell align="center">End Date</StyledTableCell>
              <StyledTableCell align="center">Priority</StyledTableCell>
              <StyledTableCell align="center">Slab Limit</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {allbanner?.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell >{row.banner_image ?
                  <img style={{ height: "2rem", width: "3rem" }} className={s["ImageBannerList"]} src={row.banner_image} onMouseOver={()=>setBannerImagePreview(row.banner_image)} onMouseLeave={()=>setBannerImagePreview("")} alt="" />
                  : null}</StyledTableCell>
                <StyledTableCell align="center">{row.id}</StyledTableCell>
                <StyledTableCell align="center">{row.category_type?.split("_").map(x => `${x}`.charAt(0).toUpperCase() + `${x}`.slice(1)).join(" ")} </StyledTableCell>
                {/* <StyledTableCell align="center">{`${row.start_date?.slice(0, 10)} ${row.start_date?.split("T")[1]?.slice(0, 5)}`}</StyledTableCell> */}
                <StyledTableCell align="center">{
                  new Date(row.start_date).toLocaleDateString("en-IN", {
                    year: "numeric",
                    month: "numeric",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                }</StyledTableCell>
                {/* <StyledTableCell align="center">{`${row.end_date?.slice(0, 10)} ${row.end_date?.split("T")[1]?.slice(0, 5)}`}</StyledTableCell> */}
                <StyledTableCell align="center">{
                  new Date(row.end_date).toLocaleDateString("en-IN", {
                    year: "numeric",
                    month: "numeric",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                }</StyledTableCell>
                <StyledTableCell align="center">{row.priority}</StyledTableCell>
                <StyledTableCell align="center">
                    {row.max_limit
                      ? " Slab Upto " + row.max_limit
                      : "Regular Slab"}
                  </StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => banner_status(row)}
                    className={`${row.status === true ? s.active_admin : s.inactive_admin}`}
                  >
                    {row.status === true ? "Active" : "Inactive"}
                  </div>
                </StyledTableCell>

                <StyledTableCell align="center">
                  {/* <CiEdit
                    onClick={() =>
                      navigate("/add-banner", {
                        state: {
                          pagetype: "Edit",
                          data: row,
                        },
                      })
                    }
                    style={{
                      fontSize: "1rem",
                      color: "var(--clr-primary)",
                      marginRight: "0.5rem",
                      cursor: "pointer",
                    }}
                  />
                  <MdDelete
                    onClick={() => {
                      setdeletePopup(true);
                      setcurrentGroup(row);
                      setdeleteId(row.id);
                    }}
                    style={{ fontSize: "1rem", color: "var(--clr-primary)", cursor: "pointer" }}
                  /> */}
                  <div className={s["astrologer-action-btn"]}>
                    <span
                      onClick={() =>
                        navigate("/add-banner", {
                          state: {
                            pagetype: "Edit",
                            data: row,
                            slabData: offerSlab
                          },
                        })
                      }
                    >
                      Edit
                    </span>
                    <span
                      onClick={() => {
                        setdeletePopup(true);
                        setcurrentGroup(row);
                        setdeleteId(row.id);
                      }}
                    >
                      Delete
                    </span>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {allbanner?.length <= 0 && <DataNotFound />}
        {allbanner?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => {setpageCount(value); localStorage.setItem("bannerIndex",value)}} page={pageCount} />
          </div>
        )}
      </div>

      <Dialog open={deletePopup} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth="true" onClose={() => setdeletePopup(false)}>
        <DialogTitle className={s.dialog_title}>
          <div>Do you want to delete {currentGroup.category_name}?</div>
        </DialogTitle>
        <DialogContent className={s.cardpopup_content}>
          <div style={{ display: "flex", gap: "1rem" }}>
            <div className={s.employee_gl_popup} onClick={() => setdeletePopup(false)}>
              Cancel
            </div>
            <div className={s.employee_gl_popup_del} onClick={() => deleteuserFunc()}>
              Delete
            </div>
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Loder loading={isLoading} />
      <ImagePreview image={bannerImagePreview}/>
    </div>
  );
};

export default BannerList;
