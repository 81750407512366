import React from 'react';
import s from "./banner.module.css";

const ImagePreview = ({image}) => {
    return (
        <div className={s['Image_Preview']}>
            <img src={image} alt=""/>
        </div>
    );
}

export default ImagePreview;