import React from "react";
import TestimonialAdd from "../components/Testimonial/TestimonialAdd";

const AddTestimonial = () => {
  return (
    <div>
      <TestimonialAdd />
    </div>
  );
};

export default AddTestimonial;
