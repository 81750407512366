import React, { useState, useEffect } from "react";
import s from "./role.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { createRole_api, role_update_api } from "../api/role";
const AddRole = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setname] = useState("");
  const pagetype = location.state.pagetype;
  console.log(location);
  useEffect(() => {
    setname(location?.state?.data?.role_name);
  }, [location]);

  const role_function = async () => {
    if (pagetype == "Add") {
      let temp = {
        role_name: name,
      };
      try {
        let res = await createRole_api(temp);
        if (res.data.status) {
          navigate("/role");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      let temp = {
        name: name,
        id: location?.state?.data?.id,
      };
      try {
        let res = await role_update_api(temp);
        if (res.data.status) {
          console.log(res);
          navigate("/role");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };
  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Name</label>
                <input type="text" className="form-control" name="name" value={name} onChange={(e) => setname(e.target.value)} placeholder="Name" />
              </div>
            </Grid>
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => role_function()}>
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddRole;
