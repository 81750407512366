import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

export const updateWallet_api = async (data) => {
  let config = {
    method: "post",
    url: getBaseUrl() + "admin/updateWalletByCSV",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getBonusAmount_api = async (data) => {
  let config = {
    method: "get",
    url: getBaseUrl() + "admin/getAppData",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    // data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateBonusAmount_api = async (data) => {
  let config = {
    method: "post",
    url: getBaseUrl() + "admin/updateAppData",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getPromotionalUploads = async (data) => {
  let config = {
    method: "get",
    url: getBaseUrl() + "admin/promotionalUploads" + `?page=${data.page}&limit=${data.limit}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
