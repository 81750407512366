import axios from "axios";
import Cookies from "js-cookie";
import { getBaseUrl } from "../../utils";

const pujaCategories = getBaseUrl() + "admin/categories";
const deleteCategory = getBaseUrl() + "admin/categories";
const addCategory = getBaseUrl() + "admin/categories";
const updateCategory = getBaseUrl() + "admin/categories";

export const getPujaCategories = async (data) => {
    let config = {
      method: "get",
      url: pujaCategories + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };

  export const updatePujaCategory = async (data, id) => {
    let config = {
      method: "patch",
      url: updateCategory + `/${id}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };

  export const deletePujaCategory = async (data) => {
    let config = {
      method: "delete",
      url: deleteCategory + `/${data.id}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };

  export const addPujaCategory = async (data) => {
    let config = {
      method: "post",
      url: addCategory,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };