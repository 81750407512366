import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./astrologer.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog, DialogActions, DialogTitle, DialogContent, Pagination } from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import { BiFilter, BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import { Grid } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import { deleteBanner_api, getBanners_api, statusUpdate_banner_api } from "../api/banner";
import { deleteAstrologer_api, getAstrologerTranscation_api, getAstrologers_api, getTransactions } from "../api/astrologer";

const AstrologerSettlement = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [isLoading, setisLoading] = useState(false);
    const [pageCount, setpageCount] = useState(1);
    const [deleteId, setdeleteId] = useState("");
    const [deletePopup, setdeletePopup] = useState(false);
    const [currentGroup, setcurrentGroup] = useState({});
    const [pageLength, setpageLength] = useState();
    const [search, setsearch] = useState("");
    console.log("location.state", location.state)

    const [allTransaction, setallTransaction] = useState([])
    const [filterData, setfilterData] = useState({
        astro_id: location.state?.data?.id,
        start_date: "",
        start_date: "",
    })

    useEffect(() => {
        getAllTransactionFunc(filterData);
    }, []);
    console.log("allTransaction", allTransaction)
    console.log("filterData", filterData)

    const handleInput = (e) => {
        setfilterData({ ...filterData, [e.target.name]: e.target.value })
    }

    async function getAllTransactionFunc(filterData) {
        setisLoading(true);
        try {
            let res = await getTransactions(filterData);
            console.log(res.data)
            if (res.data.status) {
                setallTransaction(res?.data?.data?.transactions);
                setfilterData({ ...filterData, start_date: res?.data?.data?.startDate, end_date: res?.data?.data?.endDate })
                setisLoading(false);
            } else {
                setisLoading(false);
                console.log("status false!");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "var(--clr-theme)",
            color: theme.palette.common.white,
            fontWeight: "bold",
            borderRight: "1px solid #fff",
            overflow: "hidden",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: "none",
            borderLeft: "2px solid #00000011",
            "&:last-child": {
                borderRight: "2px solid #00000011",
            },
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        borderBottom: "2px solid #00000011",
    }));

    const filterFunc = () => {
        let temp = { ...filterData, end_date: new Date(new Date(filterData.end_date).setHours(23, 59, 59)).toISOString() };
        getAllTransactionFunc(temp);
    }

    return (
        <div className="">
            <div className="beat_heading">
                <div className={s["user-list-heading"]}>
                    <div className="user-list-title">
                        <h3>Astrologer Settlement - {location.state?.data?.id}</h3>
                    </div>
                    <div className={s["user-list-search"]}>
                    </div>
                </div>
                <div className="beat_left">
                </div>
            </div>

            <div className={s["filter-container"]}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={3}>
                        <div className="form-group">
                            <label for="exampleInputEmail1">Start Date</label>
                            <input
                                className="form-control"
                                type="date"
                                disabled
                                value={new Date(filterData?.start_date).toLocaleDateString("en-IN", { year: "numeric", month: "2-digit", day: "2-digit" }).split("/").map(x => x.padStart(2, 0)).reverse().join("-")}
                                name="start_date"
                                onChange={handleInput}
                                min={new Date(filterData?.start_date).toLocaleDateString("en-IN", { year: "numeric", month: "2-digit", day: "2-digit" }).split("/").map(x => x.padStart(2, 0)).reverse().join("-")}
                                max={new Date(filterData?.end_date).toLocaleDateString("en-IN", { year: "numeric", month: "2-digit", day: "2-digit" }).split("/").map(x => x.padStart(2, 0)).reverse().join("-")}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="form-group">
                            <label for="exampleInputEmail1">End Date</label>
                            <input
                                className="form-control"
                                type="date"
                                value={new Date(filterData?.end_date).toLocaleDateString("en-IN", { year: "numeric", month: "2-digit", day: "2-digit" }).split("/").map(x => x.padStart(2, 0)).reverse().join("-")}
                                name="end_date"
                                onChange={handleInput}
                                min={new Date(filterData?.start_date).toLocaleDateString("en-IN", { year: "numeric", month: "2-digit", day: "2-digit" }).split("/").map(x => x.padStart(2, 0)).reverse().join("-")}
                            // max={new Date(filterData?.end_date).toLocaleDateString("en-IN", { year: "numeric", month: "2-digit", day: "2-digit" }).split("/").map(x => x.padStart(2, 0)).reverse().join("-")}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={3} style={{ alignSelf: "flex-end" }}>
                        <div onClick={() => filterFunc()} className={s["filter_btn"]} >Filter</div>
                    </Grid>
                    <Grid item xs={3} style={{ alignSelf: "flex-end" }}>
                        <div onClick={() => navigate("/invoice", { state: filterData })} className={s["filter_btn"]} >View Invoice</div>
                    </Grid>
                </Grid>
            </div>

            <div className="beat_table">
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">ID</StyledTableCell>
                            <StyledTableCell align="center">Title</StyledTableCell>
                            <StyledTableCell align="center">Amount</StyledTableCell>
                            <StyledTableCell align="center">Created</StyledTableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {allTransaction?.map((row, index) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell>ID : {row?.id}</StyledTableCell>
                                <StyledTableCell align="center">{row.title} </StyledTableCell>
                                <StyledTableCell align="center">{row.ammount}</StyledTableCell>
                                <StyledTableCell align="center">{row?.createdAt?.slice(0, 10)}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                {allTransaction?.length <= 0 && <DataNotFound />}
            </div>

            {/* <Dialog open={deletePopup} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth="true" onClose={() => setdeletePopup(false)}>
                <DialogTitle className={s.dialog_title}>
                    <div>Do you want to delete {currentGroup.name}?</div>
                </DialogTitle>
                <DialogContent className={s.cardpopup_content}>
                    <div style={{ display: "flex", gap: "1rem" }}>
                        <div className={s.employee_gl_popup} onClick={() => setdeletePopup(false)}>
                            Cancel
                        </div>
                        <div className={s.employee_gl_popup_del} onClick={() => deleteastroFunc()}>
                            Delete
                        </div>
                    </div>
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog> */}
            <Loder loading={isLoading} />
        </div>
    );
};

export default AstrologerSettlement;
