import React from "react";
import AddCategory from "../components/Category/AddCategory";

const AddCategoryPage = () => {
  return (
    <div>
      <AddCategory />
    </div>
  );
};

export default AddCategoryPage;
