import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

const addSkill = getBaseUrl() + "admin/addSkill";
const updateSkill = getBaseUrl() + "admin/updateSkill";
const deleteSkill = getBaseUrl() + "admin/deleteSkill";
const getSkills = getBaseUrl() + "admin/getSkills";
const status_update_skill = getBaseUrl() + "admin/updateSkill";

export const update_skill_api = async (data) => {
  let config = {
    method: "PATCH",
    url: updateSkill,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const add_skill_api = async (data) => {
  let config = {
    method: "post",
    url: addSkill,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const status_update_skill_api = async (data) => {
  let config = {
    method: "patch",
    url: status_update_skill,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const deleteSkill_api = async (data) => {
  let config = {
    method: "delete",
    url: deleteSkill,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const fetchAllSkills = async (data) => {
  let config = {
    method: "get",
    url: getSkills + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
