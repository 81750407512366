import { Card, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { addPujaAddons, updatePujaAddons } from "../api/pujaAddons";
import s from "./Puja.module.css";

const AddPujaAddons = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [ mrp, setMrp ] = useState("")
  const [ price, setPrice ] = useState("")
  const [ slug, setSlug ] = useState("");
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState("");
  const [id, setId] = useState("")

  const pagetype = location.state.pagetype;

  useEffect(() => {
    setId(location?.state?.data?.id)
    setName(location?.state?.data?.name);
    setDescription(location?.state?.data?.description);
    setStatus(location?.state?.data?.status)
    setSlug(location?.state?.data?.slug)
    setMrp(location?.state?.data?.mrp)
    setPrice(location?.state?.data?.price)
  }, [location]);

  const addonsFunc = async () => {
    if (pagetype == "Add") {
      const fd = new FormData();
      fd.append("name", name);
      fd.append("description", description);
      fd.append("mrp", mrp);
      fd.append("price", price);
      fd.append("slug", slug);
      fd.append("addonImage", file);
      fd.append("status", status);
      try {
        let res = await addPujaAddons(fd);
        if (res.data.status) {
          navigate("/puja-addons");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      const fd = new FormData();
      fd.append("name", name);
      fd.append("description", description);
      fd.append("mrp", mrp);
      fd.append("price", price);
      fd.append("slug", slug);
     {file && fd.append("addonImage", file)}
      fd.append("status", status)
      try {
        let res = await updatePujaAddons(fd, id);
        if (res.data.status) {
          navigate("/puja-addons");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };

  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["back-button"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

             {/* Name */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Title</label>
                <input type="text" className="form-control" name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Title" />
              </div>
            </Grid>

             {/* Description */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Description</label>
                <textarea type="text" className="form-control" name="description" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description" />
              </div>
            </Grid>


             {/* MRP */}
             <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">MRP</label>
                <input type="text" className="form-control" name="mrp" value={mrp} onChange={(e) => setMrp(e.target.value)} placeholder="MRP" />
              </div>
            </Grid>


             {/* Price */}
             <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Price</label>
                <input type="text" className="form-control" name="price" value={price} onChange={(e) => setPrice(e.target.value)} placeholder="Price" />
              </div>
            </Grid>


            {/* Image */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Select Image</label>
                <div className="mr-2">
                  <input type="file" className="form-control" name="img" placeholder="" accept="image/*" onChange={(e) => setFile(e.target.files[0])} />
                </div>
              </div>
            </Grid>

              {/* Slug */}
              <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Slug</label>
                <input type="text" className="form-control" name="slug" value={slug} onChange={(e) => setSlug(e.target.value)} placeholder="Slug" />
              </div>
            </Grid>

            {/* Status */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Status</label>
                <div className="mr-2">
                  <div className="form-group">
                    <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
                      <option value={"draft"}>Draft</option>
                      <option value={"published"}>Published</option>
                      {/* <option value={"deleted"}>Deleted</option> */}
                    </select>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => addonsFunc()}>
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddPujaAddons;
