import axios from "axios";
import Cookies from "js-cookie";
import { getBaseUrl } from "../../utils";

const puja = getBaseUrl() + "admin/mandirPooja";
const updatePuja = getBaseUrl() + "admin/mandirPooja";
const deletePuja = getBaseUrl() + "admin/mandirPooja";
const addPuja = getBaseUrl() + "admin/mandirPooja";

export const getMandirPujaList = async (data) => {
    let config = {
      method: "get",
      url: puja + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };

  export const updateMandirPuja = async (data, id) => {
    let config = {
      method: "patch",
      url: updatePuja + `/${id}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };

  export const deleteMandirPuja = async (data) => {
    let config = {
      method: "delete",
      url: deletePuja + `/${data.id}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };

  export const addMandirPuja = async (data) => {
    let config = {
      method: "post",
      url: addPuja,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };