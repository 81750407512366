import React, { useEffect, useState } from "react";
import s from "./astrologer.module.css";
import { Card, CircularProgress, Grid, Box, Tabs, Tab } from "@mui/material";
import { getAstrologerSlab_api } from "../api/astrologerSlab";
import { ChatSlabPricing, CallSlabPricing } from "./SlabPricing";
import { notificationHandler } from "../../utils/Notification";

export const AddAstrologerSlabPricing = ({ astrologerId }) => {
  const [astroChatSlab, setAstroChatSlab] = useState([]);
  const [astroCallSlab, setAstroCallSlab] = useState([]);
  const [value, setValue] = useState("chat");

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  async function getSlabData() {
    const res = await getAstrologerSlab_api();
    if (res.data.status) {
      var chatPricingSlabArray = [];
      var callPricingSlabArray = [];

      for (let i = 0; i < res?.data?.priceSlabs?.length; i++) {
        if (res?.data?.priceSlabs?.[i]?.consult_type === "chat") {
          chatPricingSlabArray.push(res?.data?.priceSlabs?.[i]);
        } else if (res?.data?.priceSlabs?.[i]?.consult_type === "call") {
          callPricingSlabArray.push(res?.data?.priceSlabs?.[i]);
        }
      }

      setAstroChatSlab(chatPricingSlabArray);
      setAstroCallSlab(callPricingSlabArray);
    }
  }

  useEffect(() => {
    try {
      getSlabData();
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
    }
  }, []);

  console.log("astroChatSlab", astroCallSlab);
  return (
    <>
      {astrologerId ? (
        <Card className={s["admin_container"]}>
          <div style={{ marginBottom: "1rem" }} className={s["main-heading"]}>
            <div className="main-heading">
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <h3>Astrologer Slab Pricing</h3>
                <button
                  className={s["pricingFetchButton"]}
                  onClick={() => getSlabData()}
                >
                  Re-Fetch Slabs{" "}
                </button>
              </Box>

              <Box sx={{ width: "100%" }}>
                <Tabs
                  value={value}
                  onChange={handleTabChange}
                  aria-label="wrapped label tabs example"
                >
                  <Tab value="chat" label="Chat Slab" wrapped></Tab>
                  <Tab value="call" label="Call Slab">
                    <div>hello</div>
                  </Tab>
                </Tabs>
              </Box>
              {value === "chat" ? (
                <div className={s["astro-pricing-container"]}>
                  <h5>Chat Slab Pricing</h5>
                  {astroChatSlab?.length ? (
                    // chat call pricing

                    astroChatSlab?.map((data, idx) => {
                      return <ChatSlabPricing data={data} idx={idx} astroId={astrologerId} />;
                    })
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "14px",
                        color: "red",
                        mt: "30px",
                      }}
                    >
                      Please add slabs{" "}
                    </Box>
                  )}
                </div>
              ) : (
                <div className={s["astro-pricing-container"]}>
                  <h5>Call Slab Pricing</h5>
                  {astroCallSlab?.length ? (
                    // chat call pricing

                    astroCallSlab?.map((data, idx) => {
                      return <CallSlabPricing data={data} idx={idx} astroId={astrologerId} />;
                    })
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "14px",
                        color: "red",
                        mt: "30px",
                      }}
                    >
                      Please add slabs{" "}
                    </Box>
                  )}
                </div>
              )}
            </div>
          </div>
        </Card>
      ) : (
        <Box sx={{textAlign:'center',mt:"30px", fontSize:"16px",color:"red", fontWeight:"bold",textTransform:"uppercase"}}>Please create new Astrologer first then slab Pricing in basic tab</Box>
      )}
    </>
  );
};
