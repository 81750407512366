import { Card, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { addBanner_api, updateBanner_api } from "../api/banner";
import s from "./banner.module.css";

const BannerAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pagetype = location.state.pagetype;
  const slabData = location.state.slabData;

  const [title, settitle] = useState("");
  const [type, settype] = useState("");
  const [imageType, setimageType] = useState("image");
  const [file, setfile] = useState(null);
  const [banner_url, setbanner_url] = useState("");
  const [url, seturl] = useState("");
  const [categoryType, setcategoryType] = useState("chat")
  const [startDate, setstartDate] = useState()
  const [endDate, setendDate] = useState("")
  const [priority, setpriority] = useState(1)
  const [status, setstatus] = useState()
  const [slabSelect, setSlabSelect] = useState(null);

  useEffect(() => {
    if (location.state.data) {
      console.log(location.state);
      settitle(location?.state?.data?.title);
      settype(location?.state?.data?.type);
      setbanner_url(location?.state?.data?.banner_link);
      seturl(location?.state?.data?.redirect_url);
      setcategoryType(location?.state?.data?.category_type || categoryType);
      setpriority(location?.state?.data?.priority);
      setstatus(location?.state?.data?.status);
      setstartDate(location?.state?.data?.start_date?.slice(0, -2));
      setendDate(location?.state?.data?.end_date?.slice(0, -2));
      setSlabSelect(location?.state?.data?.slab_id);
      // setfile(location?.state?.data?.banner_image)
      // let lstartDate = location?.state?.data?.start_date?.split("-")
      // if (lstartDate) setstartDate(`${lstartDate[2]}-${lstartDate[1]}-${lstartDate[0]}`)
      // let lendDate = location?.state?.data?.end_date?.split("-")
      // if (lendDate) setendDate(`${lendDate[2]}-${lendDate[1]}-${lendDate[0]}`)
    }
  }, [location]);


  const Category_function = async () => {
    if (pagetype == "Add") {
      let img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = async () => {
        if (
          1.7 < Number(img.width / img.height).toFixed(2) &&
          1.8 > Number(img.width / img.height).toFixed(2)
        ) {
          if (new Date(startDate) > new Date(endDate)) {
            return notificationHandler({
              type: "danger",
              msg: "Start Date Should Be Less Than End Date!",
            });
          }

          const fd = new FormData();
          fd.append("title", title);
          // fd.append("type", type);
          fd.append("banner_image", file || banner_url);
          // fd.append("bannerLink", banner_url);
          fd.append("url", url);
          fd.append("categoryType", categoryType);
          fd.append("startDate", new Date(startDate).toISOString());
          fd.append("endDate", new Date(endDate).toISOString());
          fd.append("priority", priority);
          slabSelect !==  null && fd.append("slabId", slabSelect)
          
         

          let res = await addBanner_api(fd);
          if (res.data.status) {
            navigate("/banner-list");
            notificationHandler({ type: "success", msg: res.data.message });
          } else {
            notificationHandler({ type: "success", msg: res.data.message });
            console.log("Some Error!");
          }
        } else {
          notificationHandler({
            type: "danger",
            msg: "Recommended Ratio 25:14 (i.e. 1000px * 560px)!",
          });
        }
      };
    }
    if (pagetype == "Edit") {

      const fd = new FormData();
      fd.append("id", location?.state?.data?.id);
      fd.append("title", title);
      // fd.append("type", type);
      if (file) fd.append("banner_image", file);
      // fd.append("bannerLink", banner_url);
      fd.append("url", url);
      fd.append("categoryType", categoryType);
      fd.append("startDate", new Date(startDate).toISOString());
      fd.append("endDate", new Date(endDate).toISOString());
      fd.append("priority", priority);
      fd.append("status", status);
      fd.append("editSlab", true);
      fd.append("slabId", slabSelect);
      

      let res = await updateBanner_api(fd);
      if (res.data.status) {
        navigate("/banner-list");
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
        console.log("Some Error!");
      }
    }
  };

  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Title</label>
                <div className="mr-2">
                  <input
                    type="text"
                    className="form-control"
                    value={title}
                    onChange={(e) => settitle(e.target.value)}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Priority</label>
                <div className="mr-2">
                  <div className="form-group">
                    <select
                      className="form-control"
                      value={priority}
                      onChange={(e) => setpriority(e.target.value)}
                    >
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                    </select>
                  </div>
                </div>
              </div>
            </Grid>
            {/* <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Type</label>
                <div className="  mr-2">
                  <div className="form-group">
                    <select className="form-control" value={type} onChange={(e) => settype(e.target.value)}>
                      <option>Select Type </option>
                      <option value="app">App </option>
                      <option value="web">Web</option>
                    </select>
                  </div>
                </div>
              </div>
            </Grid> */}
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Image Type</label>
                <div className="  mr-2">
                  <div className="form-group">
                    <select
                      className="form-control"
                      value={imageType}
                      onChange={(e) => setimageType(e.target.value)}
                    >
                      <option value="image">Image </option>
                      <option value="link">Link</option>
                    </select>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              {imageType === "image" ? (
                <div className="form-group">
                  <label for="exampleInputEmail1">Select Image</label>
                  <div className="mr-2">
                    <input
                      type="file"
                      className="form-control"
                      accept="image/*"
                      onChange={(e) => setfile(e.target.files[0])}
                    />
                  </div>
                </div>
              ) : (
                <div className="form-group">
                  <label for="exampleInputEmail1">Image URL</label>
                  <div className="mr-2">
                    <input
                      type="text"
                      className="form-control"
                      value={banner_url}
                      onChange={(e) => setbanner_url(e.target.value)}
                    />
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Start Date</label>
                <div className="mr-2">
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={startDate}
                    onChange={(e) => setstartDate(e.target.value)}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">End Date</label>
                <div className="mr-2">
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={endDate}
                    onChange={(e) => setendDate(e.target.value)}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Category Type</label>
                <div className="mr-2">
                  <div className="form-group">
                    <select
                      className="form-control"
                      value={categoryType}
                      onChange={(e) => setcategoryType(e.target.value)}
                    >
                      <option value="chat">Chat</option>
                      <option value="call">call</option>
                      <option value="kundali">Kundali</option>
                      <option value="hororscope">Hororscope</option>
                      <option value="daily_hororscope">Daily Hororscope</option>
                      <option value="panchang">Panchang</option>
                      <option value="aaj_ka_panchang">Aaj Ka Panchang</option>
                      <option value="astrology_blog">Astrology Blog</option>
                      <option value="match_making">Match Making</option>
                      <option value="wallet">Wallet</option>
                      <option value="link">Link</option>
                    </select>
                  </div>
                </div>
              </div>
            </Grid>
            {categoryType === "link" && (
              <Grid item xs={6}>
                <div className="form-group">
                  <label for="exampleInputEmail1">Redirect URL</label>
                  <div className="mr-2">
                    <input
                      type="text"
                      className="form-control"
                      value={url}
                      onChange={(e) => seturl(e.target.value)}
                    />
                  </div>
                </div>
              </Grid>
            )}
            {pagetype == "Edit" && (
              <Grid item xs={6}>
                <div className="form-group">
                  <label for="exampleInputEmail1">Status</label>
                  <div className="mr-2">
                    <div className="form-group">
                      <select
                        className="form-control"
                        value={status}
                        onChange={(e) => setstatus(e.target.value)}
                      >
                        <option value={true}>Active</option>
                        <option value={false}>InActive</option>
                      </select>
                    </div>
                  </div>
                </div>
              </Grid>
            )}
               <Grid item xs={6}>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "baseline" }}
              >
                <div className="mr-2 w-100">
                  <label
                    for="exampleInputEmail1"
                    style={{
                      width: "100%",
                      marginBottom: "2px",
                      marginTop: "8px",
                    }}
                  >
                    Select Slab
                  </label>
                  {/* <input
                    type="checkbox"
                    checked={firstTimeRecharge}
                    onChange={(e) => {
                      setFirstTimeRecharge(e.target.checked);
                    }}
                  /> */}
                  <select
                    className="form-control"
                    value={slabSelect}
                    onChange={(e) => setSlabSelect(e.target.value === "" ? null : e.target.value)}
                    >
                      <option value="">Regular</option>
                    {slabData?.map((data) => {
                      return (
                        <option value={data.id}>
                          Slab upto {data.maxLimit}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {/* <label for="exampleInputEmail1" style={{ width: "100%" }}>
                  First Recharge Offer
                </label> */}
              </div>
            </Grid>
          </Grid>

          <div
            className={s["form-login-btn"]}
            onClick={() => Category_function()}
          >
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default BannerAdd;
