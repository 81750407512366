import React, { useState, useEffect } from "react";
import s from "./skill.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { blankValidator, emailValidator } from "../../utils/Validation";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { add_skill_api, update_skill_api } from "../api/skill";
const AddSkill = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setname] = useState();
  const pagetype = location?.state?.pagetype;
  const id = location?.state?.data?.id;
  useEffect(() => {
    setname(location?.state?.data?.skill_name);
  }, [location]);

  const create_event = async () => {
    if (pagetype == "Add") {
      try {
        let temp = {
          skill_name: name,
        };
        let res = await add_skill_api(temp);
        if (res.data.status) {
          // console.log(res);
          navigate("/skill-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      try {
        let temp = {
          name: name,
          id,
        };
        let res = await update_skill_api(temp);
        if (res.data.status) {
          console.log(res);
          navigate("/skill-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };
  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Name</label>
                <input type="text" className="form-control" name="name" value={name} onChange={(e) => setname(e.target.value)} placeholder="Name" />
              </div>
            </Grid>
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => create_event()}>
            <Custombutton>{pagetype == "Add" ? "Submit" : "Update"} </Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddSkill;
